import { API_BASE_URL, client } from '@/api/common';
import { AuthenticationResponse } from '@/domain/authentication/Authentication.model';
import { AxiosResponse } from 'axios';

const AUTH_API_BASE_PATH = API_BASE_URL + `/auth`;

type ResetPasswordRequestDTO = {
    email: string;
    tenantId: string;
};

const resetPassword = async (tenantId: string, email: string): Promise<void> => {
    await client.post<ResetPasswordRequestDTO>(`${AUTH_API_BASE_PATH}/reset-password`, { email, tenantId });
};

type ConfirmNewPasswordRequestDTO = {
    tenantId: string;
    username: string;
    confirmationCode: string;
    newPassword: string;
};

const confirmNewPassword = async (tenantId: string, username: string, confirmationCode: string, newPassword: string): Promise<AuthenticationResponse> => {
    const { data } = await client.post<ConfirmNewPasswordRequestDTO, AxiosResponse<AuthenticationResponse>>(`${AUTH_API_BASE_PATH}/confirm-new-password`, {
        tenantId,
        username,
        confirmationCode,
        newPassword,
    });
    return data;
};

type SignOutRequestDTO = {
    accessToken: string;
};

const signOut = async (accessToken: string): Promise<void> => {
    await client.post<SignOutRequestDTO>(`${AUTH_API_BASE_PATH}/sign-out`, { accessToken });
};

type NewPasswordRequestDTO = {
    accessToken: string;
    oldPassword: string;
    newPassword: string;
};

const newPassword = async (accessToken: string, oldPassword: string, newPassword: string): Promise<void> => {
    await client.post<NewPasswordRequestDTO>(`${AUTH_API_BASE_PATH}/new-password`, {
        accessToken,
        oldPassword,
        newPassword,
    });
};

type SignInRequestDTO = {
    username: string;
    password: string;
    tenantId: string;
};

const signIn = async (username: string, password: string, tenantId: string): Promise<AuthenticationResponse> => {
    const { data } = await client.post<SignInRequestDTO, AxiosResponse<AuthenticationResponse>>(`${AUTH_API_BASE_PATH}/sign-in`, {
        username,
        password,
        tenantId,
    });
    return data;
};

type RefreshTokenRequestDTO = {
    tenantId: string;
    refreshToken: string;
};

const refreshToken = async (tenantId: string, refreshToken: string): Promise<AuthenticationResponse> => {
    const { data } = await client.post<RefreshTokenRequestDTO, AxiosResponse<AuthenticationResponse>>(`${AUTH_API_BASE_PATH}/refresh-token`, {
        tenantId,
        refreshToken,
    });
    return data;
};

type ImpersonateRequestDTO = {
    employeeId: number;
};

const impersonate = async (employeeId: number): Promise<AuthenticationResponse> => {
    const { data } = await client.post<ImpersonateRequestDTO, AxiosResponse<AuthenticationResponse>>(`${AUTH_API_BASE_PATH}/impersonate`, { employeeId });
    return data;
};

type OAuth2AuthorizeRequestDTO = {
    tenantId: string;
    provider: string;
    challengeCode: string;
};

const oauth2Authorize = async (tenantId: string, provider: string, challengeCode: string): Promise<string> => {
    const { data } = await client.post<OAuth2AuthorizeRequestDTO, AxiosResponse<string>>(`${AUTH_API_BASE_PATH}/oauth2/authorize`, {
        tenantId,
        provider,
        challengeCode,
    });
    return data;
};

type OAuth2TokenRequestDTO = {
    tenantId: string;
    code: string;
    challengeCodeVerifier: string;
};

const oauth2Token = async (tenantId: string, code: string, challengeCodeVerifier: string): Promise<AuthenticationResponse> => {
    const { data } = await client.post<OAuth2TokenRequestDTO, AxiosResponse<AuthenticationResponse>>(`${AUTH_API_BASE_PATH}/oauth2/token`, {
        tenantId,
        code,
        challengeCodeVerifier,
    });
    return data;
};

export const authApi = {
    signIn,
    signOut,
    newPassword,
    resetPassword,
    confirmNewPassword,
    impersonate,
    refreshToken,
    oauth2Authorize,
    oauth2Token,
};
