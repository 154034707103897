import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetLeaveTypeById, useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { LeaveTypeSettingForm } from '@/page/setting/time-management/leave-type-setting-form/LeaveTypeSettingForm';
import { FC } from 'react';
import { useParams } from 'react-router';

export const LeaveTypeSettingPage: FC = () => {
    const { leaveTypeId } = useParams();
    const mode = leaveTypeId !== 'new' ? 'edit' : 'create';

    const {
        data: leaveType,
        isPending: isPendingLeaveType,
        isError: isErrorLeaveType,
        error: errorLeaveType,
    } = useGetLeaveTypeById(mode === 'edit' ? Number(leaveTypeId) : undefined);

    const isNotFound = mode === 'edit' && !leaveType;

    const { data: leaveTypes, isLoading: isLoadingLeaveTypes, isError: isErrorLeaveTypes } = useGetLeaveTypes();
    const biggestOrder = leaveTypes?.reduce((acc, leaveType) => (leaveType.order > acc ? leaveType.order : acc), 0) ?? 0;
    const order = biggestOrder > 0 ? biggestOrder + 1 : 0;

    return (
        <StateHandler
            isLoading={isPendingLeaveType || isLoadingLeaveTypes}
            isError={isErrorLeaveType || isErrorLeaveTypes}
            isEmpty={isNotFound}
            error={errorLeaveType}
        >
            <LeaveTypeSettingForm leaveType={leaveType} order={leaveType?.order ?? order} />
        </StateHandler>
    );
};
