import Popper from '@mui/material/Popper/Popper';
import {
    FC,
    ForwardedRef,
    forwardRef,
    Fragment,
    JSX,
    KeyboardEvent,
    PropsWithChildren,
    PropsWithoutRef,
    ReactNode,
    Ref,
    RefAttributes,
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    CircularProgress,
    ClickAwayListener,
    Fade,
    IconButton,
    InputAdornment,
    Paper,
    PopperProps,
    Stack,
    StackProps,
    TextField,
    TextFieldProps,
    Tooltip,
    Typography,
} from '@mui/material';
import { findRecursiveItemById, findRecursiveItemParents, getNull } from '@/utils/object.util';
import { Cancel01Icon } from 'hugeicons-react';
import { bindPopper, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { TreeView, TreeViewItem } from '@/components/tree-view/TreeView';
import { useTreeViewApiRef } from '@/components/tree-view/useTreeViewApiRef';
import { KeyboardKey } from '@/types/keyboard';

export type RecursiveValue = TreeViewItem;

export type TreeSelectValue<Multiple> = Multiple extends true ? RecursiveValue[] : RecursiveValue | null;

export type TreeSelectProps<Multiple extends boolean | undefined> = Overwrite<
    Omit<TextFieldProps, 'defaultValue'>,
    {
        multiSelect?: Multiple;
        itemsData: RecursiveValue[];
        value?: TreeSelectValue<Multiple>;
        onChange?: (value: TreeSelectValue<Multiple>) => void;
        loading?: boolean;
        disableCloseOnSelect?: boolean;
        disablePopper?: boolean;
        // selectionPropagation has no sense in single mode
        selectionPropagation?: Multiple extends true ? boolean : false;
    }
>;

/**
 * TreeSelect is a component like with a similar behaviour to Autocomplete but with a tree view as options.
 */
const TreeSelectInnerComponent = <Multiple extends boolean | undefined = undefined>(
    props: TreeSelectProps<Multiple>,
    ref: ForwardedRef<HTMLDivElement>,
): JSX.Element => {
    const {
        multiSelect,
        itemsData,
        value,
        onChange,
        loading,
        disableCloseOnSelect = multiSelect, // keep options open in multi mode
        disablePopper,
        selectionPropagation,
        ...restTextField
    } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
    const valueLabel = getTreeSelectValueLabel(value);

    // state to store the input text field value displayed to the user
    const [inputValue, setInputValue] = useState<string>(valueLabel);
    // state to store the search query when the user searching
    // we need to make the difference between the search query and the input value
    // because we filter tree items only when the user is searching
    const [searchQuery, setSearchQuery] = useState<string>('');

    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    const popupState = usePopupState({ variant: 'popper', popupId: 'tree-select' });
    // popupState is used even if the popper is disabled to manage the display of options
    // if disablePopper is true, we force the popupState to be open
    popupState.isOpen = popupState.isOpen || !!disablePopper;

    const { t } = useTranslation();

    const treeViewApiRef = useTreeViewApiRef();

    // init the tree view on open by expanding the parents of the selected item
    useDeepCompareEffect(() => {
        if (popupState.isOpen) {
            if (!value || (multiSelect && !(value as RecursiveValue[])?.length)) {
                return;
            }
            const values = multiSelect ? (value as RecursiveValue[]) : [value as RecursiveValue];
            const parentsOfValues = values.reduce<TreeViewItem[]>((acc, v) => {
                return [...acc, ...(findRecursiveItemParents(itemsData, v.id) ?? [])];
            }, []);
            const parentsOfValuesIds = parentsOfValues.map(p => p.id.toString()) ?? [];
            setExpandedItems(prevExpandedItems => [...new Set([...prevExpandedItems, ...parentsOfValuesIds])]);
        }
    }, [value, itemsData, popupState.isOpen]);

    const getSelectedItemIds = (): Multiple extends true ? string[] : string | null => {
        if (multiSelect) {
            return ((value as RecursiveValue[])?.map(v => v.id.toString()) ?? []) as Multiple extends true ? string[] : string | null;
        }
        return ((value as RecursiveValue)?.id.toString() ?? getNull()) as Multiple extends true ? string[] : string | null;
    };

    // expansion control
    const handleItemExpansionToggle = (itemId: string, isExpanded: boolean) => {
        setExpandedItems(prev => (isExpanded ? [...prev, itemId] : prev.filter(id => id !== itemId)));
    };

    const searchAndExpand = useCallback((items: RecursiveValue[], searchValue: string): RecursiveValue[] => {
        return items
            .map(item => {
                // Check if current item match the search
                const isMatch = item.label.trim().toLowerCase().includes(searchValue.trim().toLowerCase());
                if (isMatch) {
                    return item;
                }

                // Recursively search in children
                const filteredChildren = searchAndExpand(item.children, searchValue);
                const foundInChildren = filteredChildren.length > 0;

                // toggle parent's expand state
                handleItemExpansionToggle(item.id.toString(), foundInChildren);

                if (foundInChildren) {
                    return {
                        ...item,
                        children: filteredChildren,
                    };
                }

                return undefined;
            })
            .filter((item): item is RecursiveValue => !!item); // Filter out non-matching items
    }, []);

    // filter items based on the search query
    const items = useMemo(() => (searchQuery ? searchAndExpand(itemsData, searchQuery) : itemsData), [searchAndExpand, itemsData, searchQuery]);

    // selection control on click
    const handleItemSelectionToggle = () => {
        if (!disableCloseOnSelect) {
            closePopper();
        }
    };

    const handleSelectedItemsChange = (newSelectedItems: Multiple extends true ? string[] : string | null) => {
        if (!newSelectedItems) {
            onValueChange(getNull() as TreeSelectValue<Multiple>);
            return;
        }
        if (multiSelect) {
            // RichTreeView return the last selected item first
            // move this code to the TreeView component if needs
            const reOrderedSelectedItems = [...(newSelectedItems as string[])].reverse();
            const newValues = reOrderedSelectedItems.reduce<TreeSelectValue<true>>((acc, selectedItem) => {
                const item = findRecursiveItemById(itemsData, Number(selectedItem));
                return item ? [...acc, item] : acc;
            }, []);
            onValueChange(newValues as TreeSelectValue<Multiple>);
        } else {
            const item = findRecursiveItemById(itemsData, Number(newSelectedItems));
            onValueChange((item ?? getNull()) as TreeSelectValue<Multiple>);
        }
    };

    const handleSearch = (newSearchValue: string) => {
        setSearchQuery(newSearchValue);
        setInputValue(newSearchValue);
        // ensure the popup is open
        if (!popupState.isOpen) {
            popupState.open();
        }
        // reset when search value is empty
        if (!newSearchValue) {
            setExpandedItems([]);
        }
    };

    const handleClearValue = () => {
        onValueChange((multiSelect ? [] : getNull()) as TreeSelectValue<Multiple>);
    };

    const isMulti = (value: Nullable<RecursiveValue | RecursiveValue[]>): value is RecursiveValue[] => {
        return Array.isArray(value);
    };

    const onValueChange = (newValue: TreeSelectValue<Multiple>) => {
        onChange?.(newValue);
        if (isMulti(newValue)) {
            // in the multi mode, the input value is an empty string and values are displayed in adornment
            setInputValue('');
        } else {
            setInputValue(newValue?.label ?? '');
        }
        setSearchQuery('');
    };

    const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        // select the item if there is only one item
        if (e.key === 'Enter' && items.length === 1) {
            onValueChange(items[0] as TreeSelectValue<Multiple>);
        }
        if (e.key === KeyboardKey.ESCAPE) {
            closePopper();
        }

        // arrow down and up to navigate
        const valueToFocus = multiSelect ? (value as RecursiveValue[])?.[0] : (value as RecursiveValue);
        if (e.key === KeyboardKey.ARROW_DOWN) {
            // focus the value or the first item
            treeViewApiRef.current?.focusItem(e, valueToFocus ? valueToFocus.id.toString() : items[0].id.toString());
        }
        if (e.key === KeyboardKey.ARROW_UP) {
            // focus the value or the last item
            treeViewApiRef.current?.focusItem(e, valueToFocus ? valueToFocus.id.toString() : items[items.length - 1].id.toString());
        }
    };

    const closePopper = () => {
        if (disablePopper) {
            return;
        }
        popupState.close();
        setExpandedItems([]);
    };

    const handleClickAway = () => {
        closePopper();
        setSearchQuery('');
        const labelToDisplay = multiSelect ? '' : (value as RecursiveValue)?.label;
        setInputValue(labelToDisplay ?? '');
    };

    const parentsPath =
        value && !multiSelect
            ? findRecursiveItemParents(itemsData, (value as RecursiveValue).id)
                  .map(p => p.label)
                  .join(' / ')
            : '';

    const inputSlotProps = {
        startAdornment: value ? (
            <Tooltip title={multiSelect ? valueLabel : parentsPath}>
                {/* we need this span because pointerEvents is disabled below */}
                <span>
                    {/* pointerEvents style is a hack to focus the input on click to allow keyboard navigation
                    https://github.com/mui/material-ui/issues/12058#issuecomment-451488137*/}
                    <InputAdornment style={{ pointerEvents: 'none' }} position='start' sx={{ pb: 0.25 }}>
                        {multiSelect ? (
                            <Typography overflow='hidden' textOverflow='ellipsis' sx={{ maxWidth: '150px' }}>
                                {valueLabel}
                            </Typography>
                        ) : (
                            <BreadCrumb items={findRecursiveItemParents(itemsData, (value as RecursiveValue).id)} sx={{ maxWidth: '150px' }} />
                        )}
                    </InputAdornment>
                </span>
            </Tooltip>
        ) : undefined,
        endAdornment: value ? (
            <InputAdornment position='end'>
                <IconButton
                    aria-label={'clear'}
                    onClick={e => {
                        handleClearValue();
                        e.stopPropagation();
                    }}
                >
                    <Cancel01Icon />
                </IconButton>
            </InputAdornment>
        ) : undefined,
    };

    // calculate the max height of the popper
    // returns the max available space between the anchor element and the top/bottom of the window
    const calculateOptionListMaxHeight = () => {
        if (anchorEl) {
            const rect = anchorEl.getBoundingClientRect();
            const availableSpaceBelow = window.innerHeight - rect.bottom;
            const availableSpaceAbove = rect.top;
            return Math.max(availableSpaceBelow, availableSpaceAbove);
        }
        // Default value 400px
        return 400;
    };

    const bindPopperProps = bindPopper({ ...popupState });

    return (
        <>
            <TextField
                ref={current => {
                    if (!anchorEl && current) {
                        setAnchorEl(current);
                    }
                    if (typeof ref === 'function') {
                        ref(current);
                    } else if (ref) {
                        return ref.current;
                    }
                }}
                fullWidth
                autoComplete={'off'}
                {...restTextField}
                value={inputValue}
                onChange={e => handleSearch(e.target.value)}
                onKeyDown={handleOnKeyDown}
                slotProps={{
                    ...restTextField.slotProps,
                    input: { ...inputSlotProps, ...restTextField.slotProps?.input },
                }}
                {...bindTrigger(popupState)}
            />
            <TreeSelectPopper
                placement='bottom-start'
                {...bindPopperProps}
                anchorEl={anchorEl}
                transition
                onClickAway={handleClickAway}
                disablePopper={disablePopper}
            >
                <Stack
                    sx={theme => ({
                        py: 1,
                        width: anchorEl?.clientWidth ?? '500px',
                        // remove the padding from the maxHeight
                        maxHeight: `calc(${calculateOptionListMaxHeight()}px - ${theme.spacing(2)})`,
                        overflow: 'auto',
                    })}
                >
                    {loading ? (
                        <CircularProgress size={20} sx={{ alignSelf: 'center' }} />
                    ) : (
                        <>
                            {items.length === 0 && <Typography sx={{ alignSelf: 'center' }}>{t('general.no_results')}</Typography>}
                            <TreeView
                                multiSelect={multiSelect}
                                selectionPropagation={selectionPropagation}
                                apiRef={treeViewApiRef}
                                items={items}
                                checkboxSelection={true}
                                expandedItems={expandedItems}
                                selectedItems={getSelectedItemIds()}
                                onItemExpansionToggle={(_, itemId, isExpanded) => {
                                    handleItemExpansionToggle(itemId, isExpanded);
                                }}
                                onItemSelectionToggle={handleItemSelectionToggle}
                                onSelectedItemsChange={(_, newSelectedItems) => handleSelectedItemsChange(newSelectedItems)}
                            />
                        </>
                    )}
                </Stack>
            </TreeSelectPopper>
        </>
    );
};

type TreeSelectPopperProps = PopperProps & {
    onClickAway: () => void;
    disablePopper?: boolean;
};
const TreeSelectPopper: FC<PropsWithChildren<TreeSelectPopperProps>> = props => {
    const { children, onClickAway, disablePopper, ...restProps } = props;

    if (disablePopper) {
        return children;
    }
    return (
        <Popper {...restProps} transition={true}>
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={onClickAway}>
                    <Fade {...TransitionProps}>
                        <Paper variant={'outlined'}>{children}</Paper>
                    </Fade>
                </ClickAwayListener>
            )}
        </Popper>
    );
};
// forwardRef does not work properly with generics https://www.totaltypescript.com/forwardref-with-generic-components
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
const fixedForwardRef = <T, P = {}>(
    render: (props: PropsWithoutRef<P>, ref: Ref<T>) => ReactNode,
): ((props: PropsWithoutRef<P> & RefAttributes<T>) => ReactNode) => {
    return forwardRef(render);
};

export const TreeSelect = fixedForwardRef(TreeSelectInnerComponent);

const BreadCrumb = ({ items, ...rootProps }: { items: RecursiveValue[] } & StackProps) => {
    return (
        <Stack direction='row' {...rootProps}>
            {items.map((item, index) => (
                <Fragment key={item.id}>
                    <Typography component='span' overflow='hidden' textOverflow='ellipsis' flexShrink={items.length - index}>
                        {item.label}
                    </Typography>
                    <Typography component='span'>/</Typography>
                </Fragment>
            ))}
        </Stack>
    );
};

const getTreeSelectValueLabel = (value: Nullable<RecursiveValue> | RecursiveValue[]): string => {
    if (!value) {
        return '';
    }
    if (Array.isArray(value)) {
        return value.map(v => v.label).join(', ');
    }
    return value.label;
};
