import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { Department, DepartmentCreationMutation, DepartmentNode, DepartmentUpdateMutation } from '@/domain/department/Department.model';
import {
    archiveDepartment,
    createDepartment,
    deleteDepartment,
    unarchiveDepartment,
    updateDepartment,
    updateDepartmentOrders,
} from '@/domain/department/Department.service';
import { mapDepartmentNodeOrganizationsToTreeItems, mapDepartmentNodesListToDepartments } from '@/domain/department/Department.utils';
import { Label } from '@/domain/label/Label.model';
import { isLabelUnique } from '@/domain/label/Label.service';
import { useGetDepartmentNodeOrganizations } from '@/hooks/department/Department.hook';
import { DepartmentDialog } from '@/page/setting/organization/department/department-dialog/DepartmentDialog';
import { DepartmentFormValues, getDepartmentFormSchema } from '@/page/setting/organization/department/department-dialog/DepartmentDialog.schema';
import { DepartmentTreeItem } from '@/page/setting/organization/department/department-tree/DepartmentTreeItem';
import { handleError } from '@/utils/api.util';
import { UserLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { AddSquareIcon, RemoveSquareIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TreeView, TreeViewItem } from '@/components/tree-view/TreeView';
import { DepartmentUpdateEmploymentDialog } from '@/page/setting/organization/department/employment-update-dialog/DepartmentUpdateEmploymentDialog';
import { TreeViewItemReorderPosition } from '@mui/x-tree-view-pro/internals/plugins/useTreeViewItemsReordering/useTreeViewItemsReordering.types';
import { OrderMutation } from '@/domain/common';
import { DepartmentMoveDialog } from '@/page/setting/organization/department/department-move-dialog/DepartmentMoveDialog';
import { findRecursiveItemById } from '@/utils/object.util';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { bulkCreateEmployment } from '@/domain/employment/Employment.service';
import { EmploymentBulkMutation } from '@/domain/employment/Employment.model';

type DepartmentsTreeProps = {
    translationLanguage: UserLanguage;
    atDate?: LocalDate;
};
export const DepartmentsTree: FC<DepartmentsTreeProps> = ({ translationLanguage, atDate }) => {
    const [parentToAdd, setParentToAdd] = useState<DepartmentNode>();
    const [departmentToUpdate, setDepartmentToUpdate] = useState<DepartmentNode>();

    // this states are used to save the previous version of the department and the new values to update
    const [departmentUpdateEmployment, setDepartmentUpdateEmployment] = useState<{ prev: DepartmentNode; new: DepartmentFormValues }>();

    type DepartmentMoveParams = {
        departmentNode: DepartmentNode;
        newParentId: number | undefined;
        newIndex: number;
    };
    const [departmentParamsToMove, setDepartmentParamsToMove] = useState<DepartmentMoveParams>();
    const { t } = useTranslation();

    // Fetching departments
    const {
        data: departmentNodes = [],
        isLoading: isDepartmentNodesLoading,
        isError: isDepartmentNodesError,
        error: departmentNodesError,
        refetch: refetchDepartmentsNodes,
    } = useGetDepartmentNodeOrganizations({ date: atDate });
    const flatDepartments = mapDepartmentNodesListToDepartments(departmentNodes);
    const departmentTreeItems = mapDepartmentNodeOrganizationsToTreeItems(departmentNodes, flatDepartments, translationLanguage);

    // Handle actions click
    const handleAddNodeClick = (department: DepartmentNode) => {
        setParentToAdd(department);
    };

    const handleEditNodeClick = (department: DepartmentNode) => {
        setDepartmentToUpdate(department);
    };

    const handleDeleteNodeClick = async (department: DepartmentNode) => {
        try {
            await deleteDepartment(department.id);
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    const handleArchiveClick = async (department: DepartmentNode) => {
        try {
            await archiveDepartment(department.id);
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    const handleUnarchiveClick = async (department: DepartmentNode) => {
        try {
            await unarchiveDepartment(department.id);
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    // Submitting data
    const handleSubmitAdd = async (departmentFormValue: DepartmentFormValues) => {
        const mutation = { ...mapDepartmentFormValuesToMutation(departmentFormValue), order: departmentTreeItems.length };

        const filteredLabels = getLabelsByDepartmentsAndParent(flatDepartments, mutation.parentId);

        if (!isLabelUnique(mutation.name, filteredLabels)) {
            showSnackbar(t('settings_organization.departments.unique_name_error'), 'error');
            return;
        }
        try {
            await createDepartment(mutation);
            setParentToAdd(undefined);
            refetchDepartmentsNodes().catch(handleError);
            reset();
        } catch (error) {
            handleError(error);
        }
    };

    const handleDepartmentChanges = async (department: DepartmentNode, departmentFormValues: DepartmentFormValues) => {
        const filteredLabels = getLabelsByDepartmentsAndParent(
            flatDepartments.filter(d => department.id !== d.id),
            departmentFormValues.parent?.id,
        );

        if (!isLabelUnique(departmentFormValues.name, filteredLabels)) {
            showSnackbar(t('settings_organization.departments.unique_name_error'), 'error');
            return;
        }

        // set statesto save the previous and new department values
        setDepartmentUpdateEmployment({ prev: department, new: departmentFormValues });
    };

    const handleUpdateDepartmentAndEmployments = async (
        departmentId: number,
        departmentMutation: DepartmentUpdateMutation,
        employmentMutations: EmploymentBulkMutation[],
    ) => {
        try {
            await updateDepartment(departmentId, departmentMutation);
            await refetchDepartmentsNodes();

            if (employmentMutations.length > 0) {
                await bulkCreateEmployment(employmentMutations);
            }

            closeDepartmentUpdateDialog();
        } catch (error) {
            handleError(error);
        }
    };

    const closeDepartmentUpdateDialog = () => {
        setDepartmentUpdateEmployment(undefined);
        setDepartmentToUpdate(undefined);
    };

    const isSameCostCenters = (prevDepartment: DepartmentNode, newDepartment: DepartmentFormValues) =>
        prevDepartment.departmentCostCenters.length === newDepartment.departmentCostCenters.length &&
        newDepartment.departmentCostCenters.every(newCostCenter => {
            const prevCostCenter = prevDepartment.departmentCostCenters.find(prevDcc => prevDcc.costCenter.id === newCostCenter.costCenter.id);
            return prevCostCenter?.percentage === newCostCenter.percentage;
        });

    const isSameManagers = (prevDepartment: DepartmentNode, newDepartment: DepartmentFormValues) =>
        prevDepartment.managers.length === newDepartment.managers.length &&
        newDepartment.managers.every(newManager => prevDepartment.managers.find(prevManager => prevManager.id === newManager.id));

    // return true if the department contains cost centers or managers have changed
    const openEmploymentUpdateDialog = (prevDepartment: DepartmentNode, newDepartment: DepartmentFormValues) => {
        return !isSameCostCenters(prevDepartment, newDepartment) || !isSameManagers(prevDepartment, newDepartment);
    };
    // Form to add department at the root
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(getDepartmentFormSchema(translationLanguage)),
    });

    const handleItemPositionChange = async (params: { itemId: string; oldPosition: TreeViewItemReorderPosition; newPosition: TreeViewItemReorderPosition }) => {
        const { itemId, oldPosition, newPosition } = params;
        const departmentId = Number(itemId);
        const newParentId = newPosition.parentId ? Number(newPosition.parentId) : undefined;

        try {
            if (oldPosition.parentId !== newPosition.parentId) {
                const departmentNode = findRecursiveItemById(departmentNodes, departmentId);
                if (!departmentNode) {
                    return;
                }
                // move the department to another parent will be managed in the dialog
                setDepartmentParamsToMove({ departmentNode, newParentId, newIndex: newPosition.index });
            } else {
                await moveIntoSameParent(departmentTreeItems, newParentId, oldPosition.index, newPosition.index);
                await refetchDepartmentsNodes();
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleSaveMoveIntoOtherParent = async () => {
        // Close the dialog
        setDepartmentParamsToMove(undefined);
        try {
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    const isDepartmentReorderable = (itemId: string) => {
        const departmentNode = findRecursiveItemById(departmentNodes, Number(itemId));
        return departmentNode?.children.length === 0 && !departmentNode?.archived;
    };

    // can move the department to another parent if the new parent is not archived
    const canMoveItemToNewPosition = (params: { itemId: string; oldPosition: TreeViewItemReorderPosition; newPosition: TreeViewItemReorderPosition }) => {
        const { newPosition } = params;
        const departmentNode = findRecursiveItemById(departmentNodes, Number(newPosition.parentId));
        return !departmentNode?.archived;
    };

    return (
        <Stack gap={3} mt={2}>
            {/* FORM TO ADD DEPARTMENT */}
            <Stack direction={'row'} alignItems={'flex-start'} gap={2}>
                <FieldLabel
                    name={'name'}
                    control={control}
                    fullWidth
                    language={translationLanguage}
                    placeholder={t('settings_organization.departments.add_dialog.name_field')}
                />
                <Button
                    onClick={() => {
                        handleSubmit((data: DepartmentFormValues) => {
                            handleSubmitAdd(data).catch(handleError);
                        }, console.error)();
                    }}
                >
                    {t('general.submit')}
                </Button>
            </Stack>

            <StateHandler isLoading={isDepartmentNodesLoading} isError={isDepartmentNodesError} error={departmentNodesError}>
                {/* DEPARTMENT TREE */}
                <TreeView
                    items={departmentTreeItems}
                    itemsReordering={true}
                    onItemPositionChange={handleItemPositionChange}
                    isItemReorderable={isDepartmentReorderable}
                    canMoveItemToNewPosition={canMoveItemToNewPosition}
                    slots={{
                        expandIcon: AddSquareIcon,
                        collapseIcon: RemoveSquareIcon,
                        item: DepartmentTreeItem,
                    }}
                    slotProps={{
                        expandIcon: { width: 16, height: 16 },
                        collapseIcon: { width: 16, height: 16 },
                        item: ownerState => {
                            return {
                                ...ownerState,
                                onAddClick: handleAddNodeClick,
                                onEditClick: handleEditNodeClick,
                                onDeleteClick: handleDeleteNodeClick,
                                onArchiveClick: handleArchiveClick,
                                onUnarchiveClick: handleUnarchiveClick,
                            };
                        },
                    }}
                />
            </StateHandler>

            {/* DIALOGS */}
            {parentToAdd && (
                <DepartmentDialog
                    open={true}
                    onClose={() => setParentToAdd(undefined)}
                    departments={departmentNodes}
                    defaultParent={parentToAdd}
                    translationLanguage={translationLanguage}
                    onSave={handleSubmitAdd}
                />
            )}
            {departmentToUpdate && (
                <DepartmentDialog
                    open={true}
                    onClose={closeDepartmentUpdateDialog}
                    departments={departmentNodes}
                    defaultDepartment={departmentToUpdate}
                    translationLanguage={translationLanguage}
                    onSave={(data: DepartmentFormValues) => handleDepartmentChanges(departmentToUpdate, data)}
                />
            )}
            {departmentUpdateEmployment && (
                <DepartmentUpdateEmploymentDialog
                    open={openEmploymentUpdateDialog(departmentUpdateEmployment.prev, departmentUpdateEmployment.new)}
                    onClose={closeDepartmentUpdateDialog}
                    prevValue={departmentUpdateEmployment.prev}
                    newValue={departmentUpdateEmployment.new}
                    sameManagers={isSameManagers(departmentUpdateEmployment.prev, departmentUpdateEmployment.new)}
                    sameCostCenters={isSameCostCenters(departmentUpdateEmployment.prev, departmentUpdateEmployment.new)}
                    onSave={handleUpdateDepartmentAndEmployments}
                />
            )}
            {departmentParamsToMove && (
                <DepartmentMoveDialog
                    open={true}
                    onClose={() => setDepartmentParamsToMove(undefined)}
                    departmentNodes={departmentNodes}
                    departmentNode={departmentParamsToMove.departmentNode}
                    newParentId={departmentParamsToMove.newParentId}
                    newIndex={departmentParamsToMove.newIndex}
                    onSave={handleSaveMoveIntoOtherParent}
                />
            )}
        </Stack>
    );
};

const mapDepartmentFormValuesToMutation = (departmentFormValue: DepartmentFormValues): Omit<DepartmentCreationMutation, 'order'> => {
    const { name, parent, managers, departmentCostCenters } = departmentFormValue;
    return {
        name,
        parentId: parent?.id,
        managerIds: managers.map(m => m.id),
        departmentCostCenters: departmentCostCenters.map(dcc => ({
            costCenterId: dcc.costCenter.id,
            percentage: dcc.percentage,
        })),
    };
};

const getLabelsByDepartmentsAndParent = (departments: Department[], parentId: number | undefined): Label[] => {
    return departments.filter(d => (!d.parentId ? !parentId : parentId === d.parentId)).map(d => d.name);
};

const reOrderDepartments = async (orderMutations: OrderMutation[]) => {
    if (orderMutations.length === 0) {
        return;
    }
    try {
        await updateDepartmentOrders(orderMutations);
    } catch (error) {
        handleError(error);
    }
};

const moveIntoSameParent = async (items: TreeViewItem[], parentId: number | undefined, oldIndex: number, newIndex: number) => {
    // an undefined parentId means the department is at the root
    const children = parentId ? (findRecursiveItemById(items, parentId)?.children ?? []) : items;
    const updatedReorderItems = [...children];
    updatedReorderItems.splice(oldIndex, 1);
    updatedReorderItems.splice(newIndex, 0, children[oldIndex]);

    try {
        const orderMutations = updatedReorderItems.map((item, index) => ({
            resourceId: item.id,
            order: index,
        }));
        await reOrderDepartments(orderMutations);
    } catch (error) {
        handleError(error);
    }
};
