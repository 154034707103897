import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { DepartmentNode, DepartmentUpdateMutation } from '@/domain/department/Department.model';
import { FC } from 'react';
import { Alert, AlertTitle, Button, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { useForm, useWatch } from 'react-hook-form';
import { ArrowRight02Icon } from 'hugeicons-react';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { formatCostCentersAssignment } from '@/domain/cost-center/CostCenter.service';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmploymentBulkCreateMutationItem, EmploymentBulkMutation, EmploymentCreateReason } from '@/domain/employment/Employment.model';
import { getLocalDateTestConfig } from '@/utils/datetime.util';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { Employee } from '@/domain/employee/Employee.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useGetEmployments } from '@/hooks/employment/Employment.hook';
import { DepartmentFormValues } from '@/page/setting/organization/department/department-dialog/DepartmentDialog.schema';

type DepartmentUpdateEmploymentDialogProps = {
    prevValue: DepartmentNode;
    newValue: DepartmentFormValues;
    sameManagers: boolean;
    sameCostCenters: boolean;
    onSave: (departmentId: number, departmentMutation: DepartmentUpdateMutation, employmentMutations: EmploymentBulkMutation[]) => void;
} & DialogWrapperProps;
export const DepartmentUpdateEmploymentDialog: FC<DepartmentUpdateEmploymentDialogProps> = ({
    prevValue,
    newValue,
    sameManagers,
    sameCostCenters,
    onSave,
    ...restDialog
}) => {
    const { t } = useTranslation();

    const departmentId = prevValue.id;

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(getSchema()),
        defaultValues: {
            updateEmployees: true,
        },
    });
    const [updateEmployees, effectiveDate] = useWatch({
        control,
        name: ['updateEmployees', 'effectiveDate'],
    });

    const { data: employees = [] } = useGetEmployees();

    const enableSearchEmployments = !!effectiveDate;

    // search current and future employments from effectiveDate
    const { data: employments = [] } = useGetEmployments(
        { departmentIds: [departmentId], fromDate: effectiveDate, principal: true },
        { enabled: enableSearchEmployments },
    );

    // filter employees to get only the ones that are in the department
    const employeesInDepartment = employments.reduce<Employee[]>((acc, employment) => {
        if (!acc.some(e => e.id === employment.employeeId)) {
            const foundEmployee = employees.find(e => e.id === employment.employeeId);
            if (foundEmployee) {
                return [...acc, foundEmployee];
            }
        }
        return acc;
    }, []);

    const onSubmit = async (values: DepartmentUpdateEmploymentsFormValues) => {
        const { updateEmployees, effectiveDate } = values;
        if (!updateEmployees || !effectiveDate) {
            return;
        }

        const mutations = employeesInDepartment.reduce<EmploymentBulkMutation[]>((acc, employee) => {
            const mutationsByEmployee: EmploymentBulkMutation = {
                action: 'CREATE',
                employeeId: employee.id,
                creationRequests: createBulkEmploymentMutationsByEmployee({
                    employee,
                    departmentId,
                    newDepartment: newValue,
                    effectiveDate,
                    updateManagers: !sameManagers,
                    updateCostCenters: !sameCostCenters,
                }),
            };
            return [...acc, mutationsByEmployee];
        }, [] as EmploymentBulkMutation[]);

        const updateMutation = mapDepartmentFormFullValuesToMutation(newValue, prevValue.order);
        onSave(departmentId, updateMutation, mutations);
    };

    const onSubmitOnlyDepartmentUpdates = async () => {
        const updateMutation = mapDepartmentFormFullValuesToMutation(newValue, prevValue.order);
        onSave(departmentId, updateMutation, []);
    };

    const prevCostCentersFormatted = formatCostCentersAssignment(prevValue.departmentCostCenters);
    const newCostCentersFormatted = formatCostCentersAssignment(newValue.departmentCostCenters);

    return (
        <DialogWrapper {...restDialog} header={t('settings_organization.departments.update_employments_dialog.title')} maxWidth={'md'}>
            <DialogContent>
                <Stack gap={3}>
                    <Alert severity={'warning'}>
                        <AlertTitle>{t('settings_organization.departments.update_employments_dialog.warning_title')}</AlertTitle>
                        {t('settings_organization.departments.update_employments_dialog.warning_sub_title')}
                    </Alert>

                    <FieldSwitch
                        name={'updateEmployees'}
                        control={control}
                        label={t('settings_organization.departments.update_employments_dialog.propagate_changes_field')}
                        labelPlacement={'end'}
                    />

                    {updateEmployees && (
                        <>
                            <FormControlLabel
                                control={<FieldLocalDate control={control} name={'effectiveDate'} />}
                                label={t('settings_organization.departments.update_employments_dialog.effective_date')}
                            />

                            {updateEmployees && employeesInDepartment.length > 0 && (
                                <Stack direction={'row'} alignItems={'center'} gap={1}>
                                    <Typography>{t('settings_organization.departments.update_employments_dialog.affected_employees')}</Typography>
                                    <StackedAvatars employeeAvatars={employeesInDepartment} />
                                </Stack>
                            )}

                            {!sameCostCenters && (
                                <Stack>
                                    <Typography variant={'body1bold'} sx={{ mb: 1 }}>
                                        {t('settings_organization.departments.update_employments_dialog.cost_centers')}
                                    </Typography>

                                    <Stack direction={'row'} alignItems={'center'} gap={2}>
                                        <Typography
                                            variant={'body1bold'}
                                            sx={{
                                                flex: 1,
                                                textDecoration: prevCostCentersFormatted.length ? 'line-through' : 'none',
                                                color: 'red',
                                            }}
                                        >
                                            {prevCostCentersFormatted || '-'}
                                        </Typography>
                                        <Stack direction={'row'} alignItems={'center'} gap={2} flex={1}>
                                            <ArrowRight02Icon />
                                            <Typography>{newCostCentersFormatted || '-'}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )}

                            {!sameManagers && (
                                <Stack>
                                    <Typography variant={'body1bold'} sx={{ mb: 1 }}>
                                        {t('settings_organization.departments.update_employments_dialog.managers')}
                                    </Typography>

                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
                                        {prevValue.managers.length ? <StackedAvatars employeeAvatars={prevValue.managers} sx={{ flex: 1 }} /> : '-'}
                                        <Stack direction={'row'} alignItems={'center'} gap={2} flex={1}>
                                            <ArrowRight02Icon />
                                            {newValue.managers.length ? <StackedAvatars employeeAvatars={newValue.managers} /> : '-'}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )}
                        </>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                {updateEmployees ? (
                    <Button fullWidth onClick={handleSubmit(onSubmit, console.error)}>
                        {t('settings_organization.departments.update_employments_dialog.confirm_with_changes')}
                    </Button>
                ) : (
                    <Button fullWidth onClick={onSubmitOnlyDepartmentUpdates} variant={'outlined'}>
                        {t('settings_organization.departments.update_employments_dialog.confirm_without_changes')}
                    </Button>
                )}
            </DialogActions>
        </DialogWrapper>
    );
};

const getSchema = () => {
    return yup.object().shape({
        updateEmployees: yup.boolean().required().default(true),
        effectiveDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
    });
};
type DepartmentUpdateEmploymentsFormValues = yup.InferType<ReturnType<typeof getSchema>>;

type CreateBulkParams = {
    employee: Employee;
    departmentId: number;
    newDepartment: DepartmentFormValues;
    effectiveDate: LocalDate;
    updateManagers: boolean;
    updateCostCenters: boolean;
};
const createBulkEmploymentMutationsByEmployee = ({
    employee,
    departmentId,
    newDepartment,
    effectiveDate,
    updateManagers,
    updateCostCenters,
}: CreateBulkParams) => {
    return employee.currentEmployments.reduce<EmploymentBulkCreateMutationItem[]>((accByEmployee, employment) => {
        const isSameDepartment = employment.department.id === departmentId;

        // get managers and the cost centers from the new department if the employment is in the updated department and the managers or cost centers are updated
        const managerIds = (isSameDepartment && updateManagers ? newDepartment.managers : employment.managers).map(m => m.id);
        const employmentCostCentersAssignment = (
            isSameDepartment && updateCostCenters ? newDepartment.departmentCostCenters : employment.employmentCostCenters
        ).map(dcc => ({
            costCenterId: dcc.costCenter.id,
            percentage: dcc.percentage,
        }));

        const request: EmploymentBulkCreateMutationItem = {
            // updated attributes
            startDate: effectiveDate,
            employmentCostCenters: employmentCostCentersAssignment,
            managerIds,
            employmentCreateReason: EmploymentCreateReason.NEW_ENTITY,
            // fill values from the employment
            principal: employment.principal,
            contractType: employment.contractType,
            locationId: employment.location.id,
            jobId: employment.job.id,
            jobFamilyId: employment.jobFamily?.id,
            departmentId: employment.department.id,
            percentage: employment.percentage,
        };

        return [...accByEmployee, request];
    }, [] as EmploymentBulkCreateMutationItem[]);
};

const mapDepartmentFormFullValuesToMutation = (values: DepartmentFormValues, order: number): DepartmentUpdateMutation => {
    return {
        name: values.name,
        parentId: values.parent?.id,
        managerIds: values.managers.map(m => m.id),
        departmentCostCenters: values.departmentCostCenters.map(dcc => ({
            costCenterId: dcc.costCenter.id,
            percentage: dcc.percentage,
        })),
        order,
    };
};
