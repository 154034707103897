import { FC } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { StateHandler } from '@/components/state-handler/StateHandler';
import Stack from '@mui/material/Stack/Stack';
import Divider from '@mui/material/Divider';
import { PendingRow } from '@/page/home/pending/PendingRow';
import { useTranslation } from 'react-i18next';
import { useCurrentEmployee, useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import {
    canApproveRejectOtherEmployeeLeaveRequests,
    canApproveRejectOtherEmployeeTimesheets,
    canApproveRejectProfileUpdatePendingRequest,
    canContributeEmployeeReview,
} from '@/domain/permission/Permission.service';
import { useCountEmployeePendingChanges } from '@/hooks/employee-pending-change/EmployeePendingChanges.hook';
import { useCountOngoingEmployeeReviews } from '@/hooks/employee-review/EmployeeReview.hook';
import { useCountPendingLeaveRequests } from '@/page/leave/leave-request/LeaveRequests.hook';
import { useCountTimesheetPending } from '@/hooks/timesheet/Timesheet.hook';
import Skeleton from '@mui/material/Skeleton';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';

export const PendingTasks: FC = () => {
    const { t } = useTranslation();

    const currentEmployee = useCurrentEmployee();
    const grantedPolicies = useCurrentPolicies();
    const realm = useCurrentRealm();

    const tasksPermissions = {
        canManageEmployeeInfos:
            hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE) &&
            canApproveRejectProfileUpdatePendingRequest(grantedPolicies, currentEmployee.id),
        canContributeReview: hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.REVIEWS) && canContributeEmployeeReview(grantedPolicies),
        canManageLeaveRequests:
            hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.LEAVES) &&
            canApproveRejectOtherEmployeeLeaveRequests(grantedPolicies, currentEmployee.id),
        canManageEmployeeTimesheet:
            hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.TIMESHEET) &&
            canApproveRejectOtherEmployeeTimesheets(grantedPolicies, currentEmployee.id),
    };

    const {
        data: countEmployeePendingChanges,
        isLoading: isCountEmployeePendingChangesLoading,
        isError: isCountEmployeePendingChangesError,
        error: countEmployeePendingChangesError,
    } = useCountEmployeePendingChanges(undefined, { enabled: tasksPermissions.canManageEmployeeInfos });

    const {
        data: countOngoingEmployeeReviews,
        isLoading: isCountOngoingEmployeeReviewsLoading,
        isError: isCountOngoingEmployeeReviewsError,
        error: countOngoingEmployeeReviewsError,
    } = useCountOngoingEmployeeReviews(currentEmployee, tasksPermissions.canContributeReview);

    const {
        data: countPendingLeaves,
        isLoading: isCountPendingLeavesLoading,
        isError: isCountPendingLeavesError,
        error: countPendingLeavesError,
    } = useCountPendingLeaveRequests(undefined, { enabled: tasksPermissions.canManageLeaveRequests });

    const {
        data: countTimesheetPending,
        isLoading: isCountTimesheetPendingLoading,
        isError: isCountTimesheetPendingError,
        error: countTimesheetPendingError,
    } = useCountTimesheetPending(undefined, { enabled: tasksPermissions.canManageEmployeeTimesheet });

    const isCountsLoading =
        isCountEmployeePendingChangesLoading || isCountTimesheetPendingLoading || isCountPendingLeavesLoading || isCountOngoingEmployeeReviewsLoading;
    const isCountsError = isCountEmployeePendingChangesError || isCountTimesheetPendingError || isCountPendingLeavesError || isCountOngoingEmployeeReviewsError;
    const countsError = countEmployeePendingChangesError || countTimesheetPendingError || countPendingLeavesError || countOngoingEmployeeReviewsError;

    return (
        <Stack gap={1.5} component={Paper} p={2}>
            <Typography variant='h1'>{t('home_page.my_tasks.title')}</Typography>
            <StateHandler isLoading={false} isError={isCountsError} error={countsError}>
                {/* Use loading state */}
                {isCountsLoading ? (
                    <TasksSkeleton permissions={tasksPermissions} />
                ) : (
                    <Stack divider={<Divider />} gap={1.5}>
                        {tasksPermissions.canManageEmployeeInfos && (
                            <PendingRow
                                label={t('home_page.my_tasks.profile_updates')}
                                count={countEmployeePendingChanges}
                                path={'/people/employee-requests'}
                            />
                        )}
                        {tasksPermissions.canContributeReview && (
                            <PendingRow label={t('home_page.my_tasks.ongoing_reviews')} count={countOngoingEmployeeReviews} path={'/reviews/tasks'} />
                        )}
                        {tasksPermissions.canManageLeaveRequests && (
                            <PendingRow label={t('home_page.my_tasks.pending_leaves')} count={countPendingLeaves} path={'/manage-leaves/pending'} />
                        )}
                        {tasksPermissions.canManageEmployeeTimesheet && (
                            <PendingRow label={t('home_page.my_tasks.pending_timesheets')} count={countTimesheetPending} path={'/timesheets/pending'} />
                        )}
                    </Stack>
                )}
            </StateHandler>
        </Stack>
    );
};

const TasksSkeleton: FC<{ permissions: Record<string, boolean> }> = ({ permissions }) => {
    const visiblePermissionsIndexes = Object.values(permissions)
        .filter(canSee => canSee)
        .map((_, i) => i);
    return (
        <Stack divider={<Divider />} gap={1.5}>
            {visiblePermissionsIndexes.map(i => (
                <Stack key={i} direction={'row'} gap={2}>
                    <Skeleton variant={'text'} sx={{ flex: 1 }} />
                    <Skeleton variant={'circular'} width={20} height={20} />
                </Stack>
            ))}
        </Stack>
    );
};
