import { SplitButtonGroup } from '@/components/button-group/SplitButtonGroup';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { Folder } from '@/domain/document/Document.model';
import { desktopVisible } from '@/theme/responsive';
import { getLabelTranslation } from '@/utils/language.util';
import { MenuItemProps, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { FilterType } from '@/components/filters-bar/FilterBar.type';

type Props = {
    folder: Folder;
    openFilePicker: () => void;
    onAddLinkClick: () => void;
    canManageDocument: boolean;
    filters: FilterType[];
    onFiltersChange: (filters: FilterType[]) => void;
};

export const DocumentDetailHeader: FC<Props> = ({ folder, openFilePicker, onAddLinkClick, canManageDocument, filters, onFiltersChange }) => {
    const { t } = useTranslation();

    const menuItems: MenuItemProps[] = [
        { title: t('folders.upload'), onClick: openFilePicker },
        { title: t('folders.add_link'), onClick: onAddLinkClick },
    ];

    return (
        <Stack
            direction='row'
            gap={1}
            flexWrap='wrap'
            justifyContent='space-between'
            alignItems='center'
            // to avoid the header from shrinking when actions are not visible
            minHeight={36}
        >
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body1bold'>{getLabelTranslation(folder?.name)}</Typography>
                <FiltersBar filters={filters} onFiltersChange={onFiltersChange} flex={1} />
            </Stack>

            <Stack direction='row' alignItems='center' gap={1}>
                {canManageDocument && (
                    <Stack direction='row' gap={1} sx={desktopVisible} alignItems='center'>
                        <Typography color='textPrimary'>{t('folders.visible_to')}</Typography>
                        <StackedAvatars employeeAvatars={folder?.employeeAccess} />
                    </Stack>
                )}

                {canManageDocument && <SplitButtonGroup menuItemsProps={menuItems} ariaLabel={'add documents'} />}
            </Stack>
        </Stack>
    );
};
