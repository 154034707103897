import { DayPeriod, UnitType } from '@/domain/date/Date.model';
import { getDayPeriodTranslationKey } from '@/domain/date/Date.service';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { TimesheetMode, TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { LeaveTimesheet, Timesheet, TimesheetType } from '@/domain/timesheet/Timesheet.model';
import { formatDate, formatDuration, formatInDefaultHours, getTimeFormatFromDate } from '@/utils/datetime.util';
import { FC } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { calculateDurationWithBreakFromHoursMinutes } from '../../../employee-timesheet/EmployeeTimesheet.util';
import { TimesheetRow } from '../EmployeeProfileTimesheetsHistoryPage';
import { getLabelTranslation } from '@/utils/language.util';

const TimesheetAdjustmentDisplay: FC<{
    item: Timesheet;
}> = ({ item: row }) => {
    const { t } = useTranslation();
    if (row.adjustmentCount === undefined) {
        return '';
    }

    return `${t('timesheets.adjustment')} (${formatDuration(row?.adjustmentCount)}) `;
};

const TimesheetPaymentDisplay: FC<{
    item: Timesheet;
}> = ({ item: row }) => {
    const { t } = useTranslation();
    return `${t('timesheets.payment')} (${formatDuration(row?.paymentCount)})`;
};

const TimesheetPublicHolidayDisplay: FC<{
    item: Timesheet;
}> = ({ item: row }) => {
    return `${row.comment}`;
};

const getCorrectDayPeriod = (leave: LeaveTimesheet, timesheetDate: Date): string => {
    if (!timesheetDate || (leave?.startTimePeriod === DayPeriod.ALL_DAY && leave?.endTimePeriod === DayPeriod.ALL_DAY)) {
        return '';
    }

    const DAY_FORMAT = 'EEEE';
    const startDate = formatDate(leave?.startDate, DAY_FORMAT);
    const endDate = formatDate(leave?.endDate, DAY_FORMAT);
    const dayDate = formatDate(timesheetDate, DAY_FORMAT);

    if (startDate == dayDate) {
        return getDayPeriodTranslationKey(leave.startTimePeriod);
    }
    if (endDate == dayDate) {
        return getDayPeriodTranslationKey(leave?.endTimePeriod);
    }
    return '';
};

const TimesheetLeaveDisplay: FC<{
    item: Timesheet;
    leaveType: LeaveType | undefined;
}> = ({ item: row, leaveType }) => {
    const { t } = useTranslation();

    if (!leaveType) {
        return;
    }

    const start = formatInDefaultHours(row.startAt);
    const end = formatInDefaultHours(row.endAt);
    const breakDuration = row?.breakDuration ? `(${row?.breakDuration})` : '';
    const time = row?.leaveTimesheet?.unitType === UnitType.HOURS ? `${start} - ${end} ${breakDuration}` : '';
    const leaveTypeName = getLabelTranslation(leaveType.name) ?? t('timesheets.default_leave_title');
    const dayPeriod = row?.leaveTimesheet ? t(getCorrectDayPeriod(row?.leaveTimesheet, row.startAt)) : '';

    const dayPeriodFormatted = dayPeriod ? '(' + dayPeriod + ')' : '';

    const percentage = row?.leaveTimesheet?.leavePercentage ?? 0;

    const leavePercentage = percentage > 0 && percentage < 100 ? `${percentage} %` : '';
    return `${time} ${leaveTypeName} ${dayPeriodFormatted} ${leavePercentage} `;
};

const TimesheetBasicDisplay: FC<{
    item: Timesheet;
    timesheetMode?: TimesheetMode;
}> = ({ item: row, timesheetMode }) => {
    const start = getTimeFormatFromDate(row.startAt);
    const end = getTimeFormatFromDate(row.endAt);

    const breakDuration = row?.breakDuration ? `(${row?.breakDuration})` : '';
    const time = `${start} ${end ? '- ' + end : ''} ${breakDuration}`;

    const title = timesheetMode === TimesheetMode.SIMPLIFIED ? calculateDurationWithBreakFromHoursMinutes(start, end, row?.breakDuration) : time;

    return `${title}`;
};
export const TimesheetCell = ({
    item,
    userLeaveTypes,
    timesheetSetting,
    isPlannedTimesheetCell,
}: {
    item: Partial<TimesheetRow>;
    userLeaveTypes: LeaveType[];
    timesheetSetting: TimesheetSetting | undefined;
    isPlannedTimesheetCell: boolean;
}): string => {
    // Here we are using renderToString to render the component to a string and then parse it to get the text content
    // This is useful to provide a text to ag-grid cell renderer
    const parser = new DOMParser();
    const plannedTimesheet = item.plannedTimesheet;
    const timesheet = item.timesheet;

    const timesheetItem = isPlannedTimesheetCell ? plannedTimesheet : timesheet;
    if (timesheetItem) {
        const encoded = renderToString(<TimesheetDisplay item={timesheetItem} userLeaveTypes={userLeaveTypes} timesheetSetting={timesheetSetting} />);
        return parser.parseFromString(encoded, 'text/html').body.textContent ?? '';
    }
    return '';
};
export const TimesheetDisplay: FC<{
    item: Timesheet;
    userLeaveTypes: LeaveType[];
    timesheetSetting: TimesheetSetting | undefined;
}> = ({ item, timesheetSetting, userLeaveTypes }) => {
    const shouldEarlyReturn = (item.type === TimesheetType.MISSING || item.type === TimesheetType.MISSING_AND_UNPAID) && !timesheetSetting?.autofillTimesheet;
    if (shouldEarlyReturn) {
        return;
    }
    if (
        item.type === TimesheetType.TIMESHEET ||
        item.type === TimesheetType.SHIFT_TIMESHEET ||
        item.type === TimesheetType.MISSING ||
        item.type === TimesheetType.MISSING_AND_UNPAID ||
        item.type === TimesheetType.AUTOFILL
    ) {
        return <TimesheetBasicDisplay item={item} timesheetMode={timesheetSetting?.timesheetMode} />;
    } else if (item.type === TimesheetType.TIMESHEET_PAYMENT) {
        return <TimesheetPaymentDisplay item={item} />;
    } else if (item.type === TimesheetType.TIMESHEET_ADJUSTMENT || item.type === TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT) {
        return <TimesheetAdjustmentDisplay item={item} />;
    } else if (
        item.type === TimesheetType.LEAVE ||
        item.type === TimesheetType.UNPAID_LEAVE ||
        item.type === TimesheetType.FUTURE_LEAVE ||
        item.type === TimesheetType.FUTURE_UNPAID_LEAVE ||
        item.type === TimesheetType.FUTURE_COMPENSATION ||
        item.type === TimesheetType.COMPENSATION
    ) {
        return <TimesheetLeaveDisplay item={item} leaveType={userLeaveTypes.find(lt => lt.id === item?.leaveTimesheet?.leaveTypeId)} />;
    } else if (item.type === TimesheetType.PUBLIC_HOLIDAY) {
        return <TimesheetPublicHolidayDisplay item={item} />;
    }
};
