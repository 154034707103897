import { EmptyState } from '@/components/empty-state/EmptyState';
import { ErrorEmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { FullScreenProgress } from '@/components/full-screen-progress/FullScreenProgress';
import { getAppEnv } from '@/config/config';
import { getCurrentEmployee } from '@/domain/employee/Employee.service';
import { getRealmByName } from '@/domain/realm/Realm.service';
import { employeeAuthenticated } from '@/stores/reducers/currentEmployeeSlice';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { useAppDispatch } from '@/stores/store';
import { setRealmLanguage, setUserLanguage } from '@/utils/language.util';
import { substringBeforeFirst } from '@/utils/strings.util';
import { Button, Paper, Stack } from '@mui/material';
import { isAxiosError } from 'axios';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isFutureDate, isPastDate } from '@/utils/datetime.util';
import { oauth2SignInCallback, refreshToken } from '@/domain/authentication/Authentication.service';
import { getUserAuthentication } from '@/utils/auth.util';
import { AuthenticationStatus } from '@/domain/authentication/Authentication.model';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [apiNotAvailable, setApiNotAvailable] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');

    useEffect(() => {
        setLoading(true);
        const host = window.location.host;
        const subdomain = substringBeforeFirst(host, '.');
        const env = getAppEnv();
        if (!subdomain || !env) {
            console.error('invalid url');
            setNotFound(true);
            setLoading(false);
            dispatch({ type: UiActionType.REALM_LOADED, realm: undefined });
            return;
        }
        getRealmByName(subdomain)
            .then(async realm => {
                setRealmLanguage(realm.defaultLanguage);

                // if the code is present, the user is trying to sign in using oauth2 SSO
                if (code) {
                    await oauth2SignInCallback(realm.tenantId, code);
                }

                let authenticatedUser = getUserAuthentication();

                // check if the token is expired and refresh it
                if (authenticatedUser?.refreshToken && isPastDate(authenticatedUser.expiredAt)) {
                    await refreshToken(realm.tenantId, authenticatedUser.refreshToken);
                }

                authenticatedUser = getUserAuthentication();

                if (authenticatedUser?.status === AuthenticationStatus.SUCCESS && isFutureDate(authenticatedUser.expiredAt)) {
                    try {
                        // at this point the user is signed in
                        const data = await getCurrentEmployee();
                        if (data?.employee?.language) {
                            await setUserLanguage(data?.employee?.language);
                        }
                        // set current user in redux, underlying <Router /> will render the requested page or employee profile by default
                        dispatch(employeeAuthenticated(data));
                    } finally {
                        setLoading(false);
                    }
                } else {
                    // user is not signed in, underlying <Router /> will render the login page if the user try load a page that requires authentication
                    setLoading(false);
                }

                dispatch({ type: UiActionType.REALM_LOADED, realm: realm });
            })
            .catch(error => {
                // Display error message if the API returns an error
                if (isAxiosError(error)) {
                    setApiNotAvailable(true);
                }
                console.error(error);
                dispatch({ type: UiActionType.REALM_LOADED, realm: undefined });
                setLoading(false);
            });
    }, [code, dispatch]);

    if (loading) {
        return <FullScreenProgress />;
    }

    if (apiNotAvailable || notFound) {
        return (
            <Stack
                component={Paper}
                width={{ xs: '80vw', sm: 440 }}
                p={{ xs: 3, sm: 5 }}
                left='50%'
                top='50%'
                position='absolute'
                sx={{
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <EmptyState
                    title={notFound ? t('general.errors.not_found') : t('general.errors.not_available')}
                    subTitle={notFound ? t('general.errors.not_found_sub_title') : t('general.errors.not_available_sub_title')}
                    icon={<ErrorEmptyStateIcon />}
                    action={
                        <Button variant='contained' color='primary' onClick={() => window.location.reload()}>
                            {t('general.reload')}
                        </Button>
                    }
                />
            </Stack>
        );
    }

    return children;
};
