import { LoginMethodType } from '@/domain/realm/Realm.model';
import { authApi } from '@/api/auth/AuthApi';
import { AuthenticationStatus } from '@/domain/authentication/Authentication.model';
import pkceChallenge from 'pkce-challenge';
import {
    clearImpersonatorAuthentication,
    clearUserAuthentication,
    getImpersonatorAuthentication,
    getUserAuthentication,
    setImpersonatorAuthentication,
    setUserAuthentication,
} from '@/utils/auth.util';

export const microsoftLogout = (loginMethod: LoginMethodType): void => {
    if (loginMethod === LoginMethodType.MICROSOFT) {
        window.open('https://login.microsoftonline.com/logout.srf', '_blank', 'noopener noreferrer');
    }
};

export const signIn = async (username: string, password: string, tenantId: string): Promise<AuthenticationStatus> => {
    const response = await authApi.signIn(username, password, tenantId);
    if (response.status === AuthenticationStatus.SUCCESS) {
        setUserAuthentication(response);
    }
    return response.status;
};

export const signOut = async (): Promise<void> => {
    const authenticatedUser = getUserAuthentication();
    if (authenticatedUser) {
        await authApi.signOut(authenticatedUser.accessToken);
        clearUserAuthentication();
        clearImpersonatorAuthentication();
    }
};

export const impersonateEmployee = async (employeeId: number): Promise<AuthenticationStatus> => {
    const authenticatedUser = getUserAuthentication();

    if (authenticatedUser) {
        const response = await authApi.impersonate(employeeId);
        if (response.status === AuthenticationStatus.SUCCESS) {
            setUserAuthentication(response);
            // backup the current authentication
            setImpersonatorAuthentication(authenticatedUser);
            setUserAuthentication(response);
        }
        return response.status;
    }

    return AuthenticationStatus.ERROR;
};

export const impersonationLogout = (): void => {
    const userAuthentication = getUserAuthentication();
    const impersonatorAuthentication = getImpersonatorAuthentication();
    if (userAuthentication && impersonatorAuthentication) {
        setUserAuthentication(impersonatorAuthentication);
        clearImpersonatorAuthentication();
    }
};

export const refreshToken = async (tenantId: string, refreshToken: string): Promise<AuthenticationStatus> => {
    try {
        const response = await authApi.refreshToken(tenantId, refreshToken);
        if (response.status === AuthenticationStatus.SUCCESS) {
            setUserAuthentication(response);
        }
        return response.status;
    } catch (error) {
        console.error(error);
        clearUserAuthentication();
        return AuthenticationStatus.ERROR;
    }
};

export const confirmNewPassword = async (tenantId: string, username: string, password: string, code: string): Promise<AuthenticationStatus> => {
    const response = await authApi.confirmNewPassword(tenantId, username, password, code);
    if (response.status === AuthenticationStatus.SUCCESS) {
        setUserAuthentication(response);
    }
    return response.status;
};

export const oauth2SignIn = async (tenantId: string, provider: string): Promise<string> => {
    // Generate PKCE challenge, this will be used to generate the code verifier and code challenge
    // OAuth 2.0 provides a version of the Authorization Code Flow which makes use of a Proof Key for Code Exchange (PKCE) (defined in OAuth 2.0 RFC 7636).
    // The PKCE-enhanced Authorization Code Flow introduces a secret created by the calling application that can be verified by the authorization server; this secret is called the Code Verifier.
    // This way, a malicious attacker can only intercept the Authorization Code, and they cannot exchange it for a token without the Code Verifier.
    const challenge = await pkceChallenge(128);
    // Save the code verifier in the session storage, it will be used in the callback
    window.sessionStorage.setItem('oauth_code_verifier', challenge.code_verifier);
    return authApi.oauth2Authorize(tenantId, provider, challenge.code_challenge);
};

export const oauth2SignInCallback = async (tenantId: string, code: string): Promise<AuthenticationStatus> => {
    try {
        const challengeCodeVerifier = window.sessionStorage.getItem('oauth_code_verifier');
        if (!challengeCodeVerifier) {
            console.error('Oauth code verifier not found');
            return AuthenticationStatus.ERROR;
        }
        const response = await authApi.oauth2Token(tenantId, code, challengeCodeVerifier);

        if (response.status === AuthenticationStatus.SUCCESS) {
            setUserAuthentication(response);
            // Clear the code verifier from the session storage
            window.sessionStorage.removeItem('oauth_code_verifier');
        }
        return response.status;
    } catch (error) {
        console.error(error);
        return AuthenticationStatus.ERROR;
    }
};
