import { EnabledStatus } from '@/components/enabled-status/EnabledStatus';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { Employee } from '@/domain/employee/Employee.model';
import { PermissionGroup, PermissionGroupType } from '@/domain/permission-group/PermissionGroup.model';
import { FilteringCondition, PermissionFilterType } from '@/domain/permission/Permission.model';
import { useGetPermissionGroups } from '@/hooks/permission-group/PermissionGroup.hook';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { RenderCondition } from '@/page/setting/permission/condition/Components/RenderCondition';
import { PermissionsSettingsExportButton } from '@/page/setting/permission/permission-groups-export-button/PermissionsSettingsExportButton';
import { ConfigType } from '@/page/setting/types';
import { ICellRendererParams } from 'ag-grid-community';
import { Button, Chip, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { mapFilteringConditionValueToFormValues } from '@/domain/permission-group/PermissionGroup.util';

export const PermissionsSettingsPage: FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const renderPermissionGroupMembers = (
        members: Employee[],
        filterType: PermissionFilterType,
        conditions: FilteringCondition[],
        groupType: PermissionGroupType,
    ) => {
        //done in: RP-4597 - hardcoded value for the self group to display all employees, to be changed when we allow the editing of the self group
        if (groupType === PermissionGroupType.SELF && filterType === PermissionFilterType.DYNAMIC) {
            return <Chip label={t('permissions_setting_page.all_employees')} size='small' />;
        }
        switch (filterType) {
            case PermissionFilterType.DYNAMIC:
                return <RenderConditionTooltip conditions={conditions} groupType={groupType} />;

            case PermissionFilterType.EMPLOYEE_LIST:
                return <StackedAvatars employeeAvatars={members} />;

            case PermissionFilterType.ALL_EMPLOYEES:
                return <Chip label={t('permissions_setting_page.all_employees')} size='small' />;

            case PermissionFilterType.SELF:
                return <Chip label={t('permissions_setting_page.self')} size='small' />;

            case PermissionFilterType.MANAGER:
                return <Chip label={t('permissions_setting_page.manager')} size='small' />;
            case PermissionFilterType.SUBORDINATES:
                return <Chip label={t('permissions_setting_page.subordinates')} size='small' />;

            default:
                return '';
        }
    };

    const { data: permissionGroups = [], isLoading: isLoadingPermissions, isError: isErrorPermissions, error: errorPermissions } = useGetPermissionGroups();

    const cellStatusRenderer = ({ data }: ICellRendererParams<PermissionGroup>) => <EnabledStatus enabled={data?.notificationsEnabled} />;

    const config: ConfigType<PermissionGroup> = {
        type: 'table',
        isOnRowClickActive: true,
        table: {
            columns: [
                {
                    field: 'groupName',
                    headerName: t('permissions_setting_page.group_name'),
                },
                {
                    field: 'description',
                    headerName: t('permissions_setting_page.description'),
                },
                {
                    field: 'groupMembers',
                    headerName: t('permissions_setting_page.group_members'),
                    type: 'stackedAvatars',
                    cellRenderer: ({ data }: ICellRendererParams<PermissionGroup>) =>
                        data ? renderPermissionGroupMembers(data.groupMembers, data.filterType, data.groupMemberConditions, data.groupType) : '',
                    cellClass: ['display-flex'],
                },
                {
                    field: 'targetMembers',
                    headerName: t('permissions_setting_page.target_members'),
                    type: 'stackedAvatars',
                    cellRenderer: ({ data }: ICellRendererParams<PermissionGroup>) =>
                        data ? renderPermissionGroupMembers(data.targetMembers, data.targetMembersFilterType, data.targetMemberConditions, data.groupType) : '',
                    cellClass: ['display-flex'],
                },
                {
                    field: 'notificationsEnabled',
                    headerName: t('permissions_setting_page.notifications_enabled'),
                    cellRenderer: cellStatusRenderer,
                    cellClass: ['display-flex'],
                },
            ],
        },
    };

    const goToNewPermissionPage = () => {
        navigate('/settings/permissions/new');
    };

    return (
        <Stack flex={1} gap={2}>
            <StateHandler isLoading={isLoadingPermissions} isError={isErrorPermissions} error={errorPermissions}>
                <Stack component={Paper} p={1} direction={'row'} justifyContent='right' gap={0.5}>
                    <Button onClick={goToNewPermissionPage}>
                        {t('general.add')} {t('permissions_setting_page.permission_group')}
                    </Button>
                    <PermissionsSettingsExportButton permissionGroups={permissionGroups} />
                </Stack>
                <CompanySettingsLayout options={config} data={permissionGroups} isSearchAvailable={false} />
            </StateHandler>
        </Stack>
    );
};

type RenderConditionTooltipProps = {
    conditions: FilteringCondition[];
    groupType: PermissionGroupType;
};

const RenderConditionTooltip: FC<RenderConditionTooltipProps> = ({ conditions, groupType }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    //because these groups are the default groups we want a different label for them even if they are conditional
    const isCurrentEmployees = groupType === PermissionGroupType.ALL_EMPLOYEES || groupType === PermissionGroupType.SELF;

    const label = isCurrentEmployees ? t('permissions_setting_page.current_employees') : t('permissions_setting_page.conditional');

    // we need to map the conditions to the form values to be able to render them
    const conditionsFormValues = conditions.map(condition => ({
        ...condition,
        filterConditionValues: condition.filterConditionValues.map(mapFilteringConditionValueToFormValues),
    }));

    return (
        <Tooltip
            title={
                <Stack direction={'row'} flexWrap={'wrap'}>
                    {conditionsFormValues?.map((item, index) => (
                        <Stack direction={'row'} alignItems={'center'} key={item.id}>
                            <RenderCondition item={item} isTooltip={true} />
                            {index < conditions.length - 1 && (
                                <Typography color={theme.palette.info.light} variant={'body2'}>
                                    {t('general.and')}
                                </Typography>
                            )}
                        </Stack>
                    ))}
                </Stack>
            }
        >
            <Chip label={label} size='small' />
        </Tooltip>
    );
};
