import { EmptyState } from '@/components/empty-state/EmptyState';
import { ClipboardEmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { FilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { Matrix } from '@/components/matrix/Matrix';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeReviewFeedbackResult } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { flatEmployeeReviewFeedbacksItems, isRatingQuestionFeedbackItem } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { getPropsForMatrixMode } from '@/domain/report/Report.service';
import { ReviewRatingScaleItem } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { ReviewItem } from '@/domain/review/Review.model';
import { ReviewReportMenu } from '@/page/review/review-report/ReviewReportMenu';
import { getLabelTranslation } from '@/utils/language.util';
import { Paper, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

type ReviewReportMatrixViewProps = {
    feedbacksItemsGroupByEmployee: EmployeeReviewFeedbackResult[];
    filters: FilterType[];
    onFiltersChange: (filters: FilterType[]) => void;
};

export const ReviewReportMatrixView: FC<ReviewReportMatrixViewProps> = ({ feedbacksItemsGroupByEmployee, filters, onFiltersChange }) => {
    const { t } = useTranslation();

    const [groupingFilters, setGroupingFilters] = useState<[SelectFilter, SelectFilter]>([
        {
            key: 'xAxis',
            filterName: t('reports.group_by_x_label'),
            type: 'select',
            availableRules: [],
            rule: 'EQUALS',
            selectMode: 'SYNC',
            defaultVisibility: 'visible',
            options: [],
        },
        {
            key: 'yAxis',
            filterName: t('reports.group_by_y_label'),
            type: 'select',
            availableRules: [],
            rule: 'EQUALS',
            selectMode: 'SYNC',
            defaultVisibility: 'visible',
            options: [],
        },
    ]);

    const [xAxis, yAxis] = groupingFilters;

    const flattenFeedbacksItems = flatEmployeeReviewFeedbacksItems(feedbacksItemsGroupByEmployee);
    const questions = getRatingQuestionsFromFeedbacksItems(flattenFeedbacksItems);

    useDeepCompareEffect(() => {
        const questionsOptions = questions.map(q => ({ label: getLabelTranslation(q.title), value: q.id }));
        setGroupingFilters([
            {
                ...xAxis,
                options: questionsOptions,
            },
            {
                ...yAxis,
                options: questionsOptions,
            },
        ]);
    }, [feedbacksItemsGroupByEmployee]);

    const getQuestion = (questionId: number | undefined): ReviewItem | undefined => {
        if (!questionId) {
            return;
        }
        return questions.find(q => q.id === questionId);
    };

    const xAxisValue = xAxis.value?.at(0)?.value;
    const yAxisValue = yAxis.value?.at(0)?.value;

    const xAxisQuestion = getQuestion(xAxisValue ? Number(xAxisValue) : undefined);
    const yAxisQuestion = getQuestion(yAxisValue ? Number(yAxisValue) : undefined);

    const rows = getRows({ xAxisQuestion, yAxisQuestion, feedbacksItemsGroupByEmployee });
    const groupingFilterBars = (
        <FiltersBar filters={[xAxis, yAxis]} onFiltersChange={([x, y]) => setGroupingFilters([x, y])} wrapperProps={{ color: 'secondary' }} clearable={false} />
    );

    const noMatrixState = (
        <EmptyState
            icon={<ClipboardEmptyStateIcon />}
            flex={1}
            title={t('reviews.report.no_matrix')}
            subTitle={t('reviews.report.no_matrix_onboarding_text')}
            action={groupingFilterBars}
        />
    );

    return (
        <>
            <Stack component={Paper} direction='row' p={1} justifyContent='space-between'>
                <Stack direction='row' gap={2}>
                    <ReviewReportMenu />
                    <FiltersBar filters={filters} onFiltersChange={onFiltersChange} />
                </Stack>
                {xAxisValue && groupingFilterBars}
            </Stack>

            <Stack component={Paper} p={2} flex={1} overflow='auto'>
                <StateHandler
                    isError={false}
                    isLoading={false}
                    error={undefined}
                    isEmpty={!feedbacksItemsGroupByEmployee?.length || !xAxisValue}
                    emptyStateComponent={feedbacksItemsGroupByEmployee.length ? noMatrixState : undefined}
                >
                    <Matrix
                        rows={rows ?? []}
                        groupBy={{
                            xAxis: it => it.xScaleItemId,
                            yAxis: yAxisValue ? it => it.yScaleItemId : undefined,
                        }}
                        // Matrix apply an alphabetic sort, so score is prepend to order the matrix based on it
                        // We remove it to display the label
                        getLabel={it => it.split('_')[2]}
                        isVisible={it => !!it.employee}
                        xAxis={getLabelTranslation(xAxisQuestion?.title)}
                        yAxis={getLabelTranslation(yAxisQuestion?.title)}
                    >
                        {({ items, mode }) => (
                            <Stack {...getPropsForMatrixMode(mode)}>
                                <StackedAvatars
                                    employeeAvatars={items.map(it => it.employee).filter(e => !!e)}
                                    max={mode === 'STACK' ? 10 : undefined}
                                    orientation={mode === 'STACK' ? 'vertical' : undefined}
                                    spacing={mode === 'STACK' ? 1 : undefined}
                                    size='medium'
                                    grid={mode === 'MATRIX'}
                                />
                            </Stack>
                        )}
                    </Matrix>
                </StateHandler>
            </Stack>
        </>
    );
};

const getRatingQuestionsFromFeedbacksItems = (feedbacksItems: { reviewItem: ReviewItem }[]): ReviewItem[] => {
    const questions = feedbacksItems.filter(isRatingQuestionFeedbackItem).map(feedbackItem => feedbackItem.reviewItem);
    return Array.from(new Set(questions));
};

const getRows = ({
    xAxisQuestion,
    yAxisQuestion,
    feedbacksItemsGroupByEmployee,
}: {
    xAxisQuestion: ReviewItem | undefined;
    yAxisQuestion: ReviewItem | undefined;
    feedbacksItemsGroupByEmployee: EmployeeReviewFeedbackResult[];
}): {
    employee?: EmployeeAvatar;
    xScaleItemId: string;
    yScaleItemId: string;
}[] => {
    const xScale = xAxisQuestion?.rating?.items ?? [];
    const yScale = yAxisQuestion?.rating?.items ?? [];

    const matrixTemplate: {
        xScaleItemId: string;
        yScaleItemId: string;
    }[] = xScale.flatMap(x =>
        // if no y axis, return only the x axis
        !yAxisQuestion
            ? [{ xScaleItemId: getScaleItemId(x), yScaleItemId: '' }]
            : yScale.map(y => ({
                  xScaleItemId: getScaleItemId(x),
                  yScaleItemId: getScaleItemId(y) ?? '',
              })),
    );

    // create matrix with response data
    const matrixWithoutEmpty = feedbacksItemsGroupByEmployee.map(({ employee, items }) => {
        const responseX = items.find(item => item.reviewItem.id === xAxisQuestion?.id);
        const xScaleItem = responseX?.reviewItem?.rating?.items.find(item => item.score === responseX.score);
        const x = getScaleItemId(xScaleItem);

        const responseY = items.find(item => item.reviewItem.id === yAxisQuestion?.id);
        const yScaleItem = responseY?.reviewItem?.rating?.items.find(item => item.score === responseY.score);
        const y = yScaleItem ? getScaleItemId(yScaleItem) : '';

        return {
            employee,
            xScaleItemId: x,
            yScaleItemId: y,
        };
    });

    return [...matrixTemplate, ...matrixWithoutEmpty];
};

const getScaleItemId = (scaleItem: ReviewRatingScaleItem | undefined) => (scaleItem ? `score_${scaleItem.score}_${getLabelTranslation(scaleItem.label)}` : '');
