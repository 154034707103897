import { FC } from 'react';
import { Path, Svg, View } from '@react-pdf/renderer';

type EmployeeReviewStarsIconsPDFProps = {
    score: number | undefined;
};

export const EmployeeReviewStarsIconsPDF: FC<EmployeeReviewStarsIconsPDFProps> = ({ score }) => {
    if (!score) {
        return <></>;
    }

    const items = [...Array(score)].map((_, index) => index);

    return (
        <View style={{ display: 'flex', flexDirection: 'row' }}>
            {items.map(item => (
                <Svg key={item} viewBox={'0 0 24 24'} style={{ width: 12, height: 12 }}>
                    {/*This is the start svg icon*/}
                    <Path
                        fill={'#000'}
                        d='M17.9189 14.3201C17.6599 14.5711 17.5409 14.9341 17.5999 15.2901L18.4889 20.2101C18.5639 20.6271 18.3879 21.0491 18.0389 21.2901C17.6969 21.5401 17.2419 21.5701 16.8689 21.3701L12.4399 19.0601C12.2859 18.9781 12.1149 18.9341 11.9399 18.9291H11.6689C11.5749 18.9431 11.4829 18.9731 11.3989 19.0191L6.96888 21.3401C6.74988 21.4501 6.50188 21.4891 6.25888 21.4501C5.66688 21.3381 5.27188 20.7741 5.36888 20.1791L6.25888 15.2591C6.31788 14.9001 6.19888 14.5351 5.93988 14.2801L2.32888 10.7801C2.02688 10.4871 1.92188 10.0471 2.05988 9.65012C2.19388 9.25412 2.53588 8.96512 2.94888 8.90012L7.91888 8.17912C8.29688 8.14012 8.62888 7.91012 8.79888 7.57012L10.9889 3.08012C11.0409 2.98012 11.1079 2.88812 11.1889 2.81012L11.2789 2.74012C11.3259 2.68812 11.3799 2.64512 11.4399 2.61012L11.5489 2.57012L11.7189 2.50012H12.1399C12.5159 2.53912 12.8469 2.76412 13.0199 3.10012L15.2389 7.57012C15.3989 7.89712 15.7099 8.12412 16.0689 8.17912L21.0389 8.90012C21.4589 8.96012 21.8099 9.25012 21.9489 9.65012C22.0799 10.0511 21.9669 10.4911 21.6589 10.7801L17.9189 14.3201Z'
                    />
                </Svg>
            ))}
        </View>
    );
};
