import { FC } from 'react';
import { RatingFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import {
    EmployeeReviewFeedbackSummarySkillFormType,
    EmployeeReviewSummaryFeedbackQuestionFormType,
    EmployeeReviewSummaryFeedbackSkillQuestionFormType,
} from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { EmployeeReviewFeedbackQuestionPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackQuestionPDF';
import { EmployeeReviewFeedbackFromPreparationPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/EmployeeReviewFeedbackFromPreparationPDF';
import { useTranslation } from 'react-i18next';
import { Text } from '@react-pdf/renderer';
import { useTheme } from '@mui/material';
import { assignLocalId } from '@/utils/object.util';

type EmployeeReviewFeedbackSummaryQuestionPDFProps<
    T extends
        | EmployeeReviewSummaryFeedbackQuestionFormType
        | EmployeeReviewSummaryFeedbackSkillQuestionFormType
        | (EmployeeReviewFeedbackSummarySkillFormType & {
              title: string;
          }),
> = {
    questionItem: T | undefined;
    rating: RatingFormType | undefined;
};

export const EmployeeReviewSummaryFeedbackQuestionPDF: FC<
    EmployeeReviewFeedbackSummaryQuestionPDFProps<
        | EmployeeReviewSummaryFeedbackQuestionFormType
        | EmployeeReviewSummaryFeedbackSkillQuestionFormType
        | (EmployeeReviewFeedbackSummarySkillFormType & {
              title: string;
          })
    >
> = ({ questionItem, rating }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    if (!questionItem) {
        return <></>;
    }

    return (
        <EmployeeReviewFeedbackQuestionPDF questionItem={questionItem} rating={rating}>
            {questionItem.selfFeedbackQuestion && (
                <EmployeeReviewFeedbackFromPreparationPDF employeeReviewFeedbackForm={questionItem.selfFeedbackQuestion} rating={rating} />
            )}

            {questionItem.managerFeedbackQuestion?.map(managerFeedbackSkillQuestion => (
                <EmployeeReviewFeedbackFromPreparationPDF
                    key={assignLocalId(managerFeedbackSkillQuestion).localId}
                    employeeReviewFeedbackForm={managerFeedbackSkillQuestion}
                    rating={rating}
                />
            ))}

            {questionItem.upwardFeedbackQuestion?.map(upwardFeedbackSkillQuestion => (
                <EmployeeReviewFeedbackFromPreparationPDF
                    key={assignLocalId(upwardFeedbackSkillQuestion).localId}
                    employeeReviewFeedbackForm={upwardFeedbackSkillQuestion}
                    rating={rating}
                />
            ))}
            {questionItem.peerFeedbackQuestion?.map(peerFeedbackSkillQuestion => (
                <EmployeeReviewFeedbackFromPreparationPDF
                    key={assignLocalId(peerFeedbackSkillQuestion).localId}
                    employeeReviewFeedbackForm={peerFeedbackSkillQuestion}
                    rating={rating}
                />
            ))}

            <Text
                style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    paddingTop: theme.spacing(1),
                }}
            >
                {t('reviews.summary.summary_subtitle')}
            </Text>
        </EmployeeReviewFeedbackQuestionPDF>
    );
};
