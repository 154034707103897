import { API_BASE_URL, client } from '@/api/common';
import { DepartmentDTO } from '@/api/department/Department.api';
import { EmployeeAvatarDTO } from '@/api/employee/Employee.api';
import { JobFamilyDTO } from '@/api/job-family/JobFamily.api';
import { JobDTO } from '@/api/job/Job.api';
import { LocationDTO } from '@/api/location/Location.api';
import { CostCenterAssignmentMutation } from '@/domain/cost-center/CostCenter.model';
import {
    ContractCreationMutation,
    Employment,
    EmploymentBulkMutation,
    EmploymentCreationMutation,
    EmploymentsSearchRequest,
    EmploymentUpdateMutation,
    ModifyContractMutation,
} from '@/domain/employment/Employment.model';
import { AxiosResponse } from 'axios';

type EmploymentItemDTO = {
    location: LocationDTO;
    job: JobDTO;
    jobFamily?: JobFamilyDTO;
    department: DepartmentDTO;
    managers?: EmployeeAvatarDTO[];
};

export type EmploymentDTO = StrictOverwrite<Employment, EmploymentItemDTO>;

type ModifyContractRequestDTO = ModifyContractMutation;

export const mapEmploymentDTO = (employment: EmploymentDTO): Employment => {
    return {
        ...employment,
        managers: employment.managers ?? [],
    };
};

const searchEmployments = async (searchRequest: EmploymentsSearchRequest): Promise<Employment[]> => {
    const url = API_BASE_URL + '/employments/search';

    const data = (await client.post<EmploymentDTO, AxiosResponse<EmploymentDTO[]>, EmploymentsSearchRequest>(url, searchRequest)).data;
    return data.map(employment => mapEmploymentDTO(employment));
};

const getEmploymentById = (id: number): Promise<Employment> => {
    const url = API_BASE_URL + `/employments/${id}`;
    return client.get(url);
};

const cancelTermination = async (principalEmploymentId: number): Promise<Employment> => {
    const url = API_BASE_URL + `/employments/${principalEmploymentId}/termination/cancel`;
    return (await client.put<Employment, AxiosResponse<Employment>>(url)).data;
};

const modifyContract = async (principalEmploymentId: number, mutation: ModifyContractMutation): Promise<Employment> => {
    const url = API_BASE_URL + `/employments/${principalEmploymentId}/modify-contract`;
    const response = await client.post<EmploymentDTO, AxiosResponse<EmploymentDTO>, ModifyContractRequestDTO>(url, mutation);
    return mapEmploymentDTO(response.data);
};

type EmploymentItemMutationDTO = {
    locationId: number;
    jobId: number;
    jobFamilyId?: number;
    departmentId: number;
    managerIds: number[];
    percentage: number;
    employmentCostCenters: CostCenterAssignmentMutation[];
};

type EmploymentCreationDTO = Omit<EmploymentCreationMutation, 'items'> & EmploymentItemMutationDTO;

type EmploymentUpdateDTO = Omit<EmploymentUpdateMutation, 'items'> & EmploymentItemMutationDTO;

type EmploymentBulkCreationDTO = EmploymentBulkMutation;

const createEmployment = async (mutation: EmploymentCreationMutation): Promise<Employment[]> => {
    const url = API_BASE_URL + `/employments`;
    const { items, ...restMutation } = mutation;
    const payload = items.map(item => ({
        ...restMutation,
        // percentage is not used in FE, but it is required in BE
        percentage: Number((100 / items.length).toFixed(0)),
        principal: item.principal,
        locationId: item.locationId,
        jobId: item.jobId,
        jobFamilyId: item.jobFamilyId,
        departmentId: item.departmentId,
        managerIds: item.managerIds,
        employmentCostCenters: item.employmentCostCenters,
    }));
    const response = await client.post<EmploymentDTO[], AxiosResponse<EmploymentDTO[]>, EmploymentCreationDTO[]>(url, replaceFirstItemPercentage(payload));
    return response.data.map(mapEmploymentDTO);
};

const updateEmployment = async (id: number, mutation: EmploymentUpdateMutation): Promise<Employment[]> => {
    const url = API_BASE_URL + `/employments/${id}`;
    const { items, ...restMutation } = mutation;

    const payload = items.map(item => ({
        ...restMutation,
        id: item.id,
        // percentage is not used in FE, but it is required in BE
        percentage: Number((100 / items.length).toFixed(0)),
        principal: item.principal,
        locationId: item.locationId,
        jobId: item.jobId,
        jobFamilyId: item.jobFamilyId,
        departmentId: item.departmentId,
        managerIds: item.managerIds,
        employmentCostCenters: item.employmentCostCenters,
    }));

    const response = await client.put<EmploymentDTO[], AxiosResponse<EmploymentDTO[]>, EmploymentUpdateDTO[]>(url, replaceFirstItemPercentage(payload));
    return response.data.map(mapEmploymentDTO);
};

const bulkCreateEmployment = async (mutations: EmploymentBulkMutation[]): Promise<Employment[]> => {
    const { data } = await client.post<EmploymentDTO[], AxiosResponse<EmploymentDTO[]>, EmploymentBulkCreationDTO[]>(
        API_BASE_URL + '/employments/bulk',
        mutations,
    );
    return data.map(mapEmploymentDTO);
};
/**
 * @deprecated In the future user will be able to set percentage for each item
 * Set percentage dynamically based on the number of items in the payload, first item is the rest of the percentage to get 100%
 **/
const replaceFirstItemPercentage = <T extends { percentage: number }>(items: T[]): T[] => {
    return items.map((item, index) => ({
        ...item,
        percentage: index === 0 ? 100 - items.slice(1).reduce((acc, item) => acc + item.percentage, 0) : item.percentage,
    }));
};

const deleteEmployment = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/employments/${id}`;
    await client.delete(url);
};

const createContract = async (mutation: ContractCreationMutation): Promise<Employment[]> => {
    return createEmployment(mutation);
};

const deleteContract = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/employments/${id}/delete-contract`;
    await client.delete(url);
};

export const employmentAPI = {
    searchEmployments,
    getEmploymentById,
    cancelTermination,
    modifyContract,
    createEmployment,
    updateEmployment,
    bulkCreateEmployment,
    deleteEmployment,
    createContract,
    deleteContract,
};
