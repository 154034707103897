import { Spinner } from '@/components/spinner/Spinner';
import { DashboardLazyPage } from '@/page/home/homeLazyPages';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { Suspense } from 'react';
import { RouteObject } from 'react-router';

export const dashboardRoutes: RouteObject[] = [
    {
        path: '/dashboard',
        element: (
            <Suspense fallback={<Spinner />}>
                <DashboardLazyPage />
            </Suspense>
        ),
        errorElement: <RouterBoundary />,
    },
];
