import { timesheetPaymentsSearch } from '@/domain/timesheet-payment/TimesheetPayment.service';
import {
    countPendingTimesheets,
    getClockOutForceBreakDuration,
    getEmployeeMonthTimesheet,
    searchEmployeeMonthlyTimesheets,
    searchEmployeeTimesheets,
    searchPendingTimesheets,
    searchTimesheets,
} from '@/domain/timesheet/Timesheet.service';
import { createQueryHook } from '@/page/Query.type';

export const useGetEmployeeTimesheets = createQueryHook('employeeTimesheets', searchEmployeeTimesheets);

export const useGetPendingTimesheets = createQueryHook('pendingTimesheets', searchPendingTimesheets);

export const useCountTimesheetPending = createQueryHook('countTimesheetPending', countPendingTimesheets);

export const useGetTimesheets = createQueryHook('timesheets', searchTimesheets);

export const useGetClockOutForceBreakDuration = createQueryHook('clockOutForceBreakDuration', getClockOutForceBreakDuration);

export const useGetEmployeeMonthlyTimesheets = createQueryHook('employeeMonthlyTimesheets', searchEmployeeMonthlyTimesheets);

export const useTimesheetPaymentsSearch = createQueryHook('timesheetPaymentsSearch', timesheetPaymentsSearch);

export const useGetEmployeeMonthTimesheet = createQueryHook('employeeMonthTimesheet', getEmployeeMonthTimesheet);
