import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { getTableHeight } from '@/components/ag-grid-wrapper/AgGridWrapper.util';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { formatCostCentersAssignment } from '@/domain/cost-center/CostCenter.service';
import { Department } from '@/domain/department/Department.model';
import { Employee, EmployeeAvatar } from '@/domain/employee/Employee.model';
import { Employment, EmploymentCreateReason, EmploymentUpdateMutation } from '@/domain/employment/Employment.model';
import { deleteEmployment, updateEmployment } from '@/domain/employment/Employment.service';
import { Job } from '@/domain/job/Job.model';
import { Location } from '@/domain/location/Location.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { useGetEmployments } from '@/hooks/employment/Employment.hook';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { EmploymentDialog } from '@/domain-ui/employment/employment-dialog/EmploymentDialog';
import { EmploymentDialogFormValues } from '@/page/employee-profile/employee-profile-info/EmploymentSection/employment.schema';
import { handleError } from '@/utils/api.util';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DialogContent, Stack, Typography } from '@mui/material';
import { ArrowRight02Icon } from 'hugeicons-react';
import i18next from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DepartmentHierarchyLabel } from '@/domain-ui/department/DepartmentHierarchyLabel';
import { mapToEmploymentDefaultValues } from '@/domain-ui/employment/employment-dialog/EmploymentDialog.util';

export const EmploymentsHistoryDialog: FC<DialogWrapperProps & { employee: Employee }> = ({ employee, ...rest }) => {
    const { t } = useTranslation();

    const [employmentToUpdate, setEmploymentToUpdate] = useState<Employment>();
    const [employmentToDelete, setEmploymentToDelete] = useState<Employment>();

    const { data: employments = [], isLoading: isEmploymentsLoading, refetch: refetchEmployments } = useGetEmployments({ employeeIds: [employee.id] });

    const tableHeight = getTableHeight({ rowsLength: employments.length, disableFooter: true });

    const cellActionRenderer = (params: ICellRendererParams<Employment>) => {
        if (!params.data) {
            return;
        }
        const isPrincipal = params.data.principal;

        if (!isPrincipal) {
            return;
        }

        const employmentCreateReason = params.data.employmentCreateReason;

        const isDeletable = employmentCreateReason !== EmploymentCreateReason.REHIRED && employmentCreateReason !== EmploymentCreateReason.NEW_EMPLOYEE;

        const deleteActionItem = {
            title: t('general.delete'),
            onClick: () => setEmploymentToDelete(params.data),
        };

        return (
            <BasicMenu
                items={[
                    {
                        title: t('general.edit'),
                        onClick: () => setEmploymentToUpdate(params.data),
                    },
                    // Add delete action only if group can be deleted
                    ...(isDeletable ? [deleteActionItem] : []),
                ]}
            />
        );
    };
    const actionMenu = {
        type: 'actionMenu',
        cellRenderer: cellActionRenderer,
    };

    const getEmploymentsDialogData = (employment: Employment) => {
        const group = employments.filter(e => e.startDate === employment.startDate);
        return mapToEmploymentDefaultValues(group);
    };
    const handleUpdate = (selectedEmployment: Employment) => async (employmentFormValues: EmploymentDialogFormValues) => {
        const mutation: EmploymentUpdateMutation = getUpdateMutation(selectedEmployment, employmentFormValues);
        try {
            await updateEmployment(selectedEmployment.id, mutation);
            refetchEmployments();
            setEmploymentToUpdate(undefined);
        } catch (error) {
            handleError(error);
        }
    };

    const handleDelete = async (employmentToDeleteId: number) => {
        try {
            await deleteEmployment(employmentToDeleteId);
            refetchEmployments();
            setEmploymentToDelete(undefined);
        } catch (error) {
            handleError(error);
        }
    };
    const hasJobFamilyFeature = useRealmFeatureEnabled(RealmFeaturesType.JOB_FAMILIES);
    const hasCostCentersFeature = useRealmFeatureEnabled(RealmFeaturesType.COST_CENTERS);

    // Sort by start date first and by principal employment
    const employmentsSorted = [...employments].sort((a, b) => {
        if (a.startDate < b.startDate) {
            return 1;
        }
        if (a.startDate > b.startDate) {
            return -1;
        }
        return a.principal ? -1 : 1;
    });

    return (
        <DialogWrapper header={t('employee.employment.history.title')} maxWidth='lg' {...rest}>
            <DialogContent sx={{ pb: 2 }}>
                <Stack width='100%' minHeight={100} height={tableHeight}>
                    <AgGridWrapper
                        rowData={employmentsSorted}
                        columnDefs={getEmploymentColumnDefs(actionMenu, {
                            hasJobFamilyFeature,
                            hasCostCentersFeature,
                        })}
                        compact
                        statusBar={undefined}
                        loading={isEmploymentsLoading}
                    />
                </Stack>
                {employmentToUpdate && (
                    <EmploymentDialog
                        open={true}
                        employee={employee}
                        defaultValues={getEmploymentsDialogData(employmentToUpdate)}
                        onSave={handleUpdate(employmentToUpdate)}
                        onClose={() => setEmploymentToUpdate(undefined)}
                    />
                )}
                {employmentToDelete && (
                    <ConfirmDialog
                        open={!!employmentToDelete}
                        onConfirm={() => handleDelete(employmentToDelete?.id)}
                        onClose={() => setEmploymentToDelete(undefined)}
                    />
                )}
            </DialogContent>
        </DialogWrapper>
    );
};

const getEmploymentColumnDefs = (
    actionMenu: ColDef<Employment>,
    {
        hasJobFamilyFeature,
        hasCostCentersFeature,
    }: {
        hasJobFamilyFeature: boolean;
        hasCostCentersFeature: boolean;
    },
): ColDef<Employment>[] => {
    return [
        {
            field: 'employmentCreateReason',
            headerName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_CREATION_REASON' }),
            valueFormatter: ({ value, data }: { value: EmploymentCreateReason; data?: Employment }) =>
                data?.principal ? i18next.t('employee.employment.employment_create_reason', { context: value }) : '',
        },
        {
            field: 'startDate',
            headerName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_START_DATE' }),
            valueFormatter: ({ value }: { value: Date }) => formatInDefaultDate(value),
            cellRenderer: ({ data }: ICellRendererParams<Employment>) => (data?.principal ? <EmploymentDates employment={data} /> : ''),
            cellClass: ['display-flex'],
        },

        {
            field: 'job',
            headerName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_JOB' }),
            valueFormatter: ({ value }: { value: Job }) => getLabelTranslation(value?.name),
        },
        {
            field: 'jobFamily.name',
            headerName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_JOB_FAMILY' }),
            hide: hasJobFamilyFeature,
        },
        {
            field: 'department',
            headerName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_DEPARTMENT' }),
            valueFormatter: ({ value }: { value: Department }) => getLabelTranslation(value.name),
            cellRenderer: ({ data }: ICellRendererParams<Employment>) =>
                data?.department.id ? <DepartmentHierarchyLabel departmentId={data.department.id} /> : '',
        },
        {
            field: 'managers',
            headerName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_MANAGER' }),
            valueFormatter: ({ value }: { value: EmployeeAvatar[] }) => value.map(manager => manager.displayName).join(', '),
        },
        {
            field: 'location',
            headerName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_LOCATION' }),
            valueFormatter: ({ value }: { value: Location }) => value.name,
        },
        {
            field: 'employmentCostCenters',
            headerName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_COST_CENTERS' }),
            valueFormatter: ({ value }: { value: Employment['employmentCostCenters'] }) => formatCostCentersAssignment(value),
            hide: !hasCostCentersFeature,
        },
        actionMenu,
    ];
};

export const EmploymentDates: FC<{ employment: Employment }> = ({ employment }) => {
    return (
        <Stack direction='row' gap={0.5} alignItems={'center'}>
            <Typography>{formatInDefaultDate(employment.startDate)}</Typography>
            {!!employment.endDate && <ArrowRight02Icon size={18} />}
            <Typography>{formatInDefaultDate(employment.endDate) ?? ''}</Typography>
        </Stack>
    );
};

const getUpdateMutation = (selectedEmployment: Employment, employmentFormValues: EmploymentDialogFormValues): EmploymentUpdateMutation => {
    const items = employmentFormValues.items.map((item, index) => ({
        id: item.id ?? undefined,
        principal: index === 0,
        locationId: item.location?.id,
        departmentId: item?.department?.id,
        jobId: item?.job?.id,
        jobFamilyId: item?.jobFamily?.id,
        managerIds: item.managers.flatMap(manager => manager.id),
        employmentCostCenters: item.employmentCostCenters.map(ecc => ({ costCenterId: ecc.costCenter.id, percentage: ecc.percentage })) ?? [],
    })) satisfies EmploymentUpdateMutation['items'];
    return {
        // Contract type is not editable in the dialog so we can use the principal employment's contract type
        contractType: selectedEmployment.contractType,
        startDate: employmentFormValues?.startDate,
        probationEndDate: selectedEmployment?.probationEndDate,
        endDate: selectedEmployment.endDate,
        employmentCreateReason: employmentFormValues?.employmentUpdateReason,
        items,
    };
};
