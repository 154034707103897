import { getApiBaseUrl, getAppConfig } from '@/config/config';
import { ImportRequest } from '@/domain/import/Import.model';
import { getUserAuthentication } from '@/utils/auth.util';
import axios, { InternalAxiosRequestConfig } from 'axios';

const API_VERSION = 'v1';
export const API_BASE_URL = getApiBaseUrl() + '/' + API_VERSION;
export const client = axios.create();

// Spring security doesn't allow to send the Authorization header in public routes
// see: https://github.com/spring-projects/spring-security/issues/12599
const UNSECURE_ROUTES = [
    '/v1/realms/',
    '/v1/realms/search',
    '/v1/surveys/auth',
    '/v1/employees/login-method',
    '/v1/auth/sign-in',
    '/v1/auth/confirm-new-password',
    '/v1/auth/reset-password',
    '/v1/auth/refresh-token',
    '/v1/auth/oauth2/authorize',
    '/v1/auth/oauth2/token',
];

const onRequest = async (requestConfig: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    try {
        const authenticatedUser = getUserAuthentication();
        if (!UNSECURE_ROUTES.some(route => requestConfig.url?.includes(route))) {
            requestConfig.headers['Authorization'] = `Bearer ${authenticatedUser?.idToken}`;
        }
        // default content type header to "application/json"
        if (!requestConfig.headers['Content-Type']) {
            requestConfig.headers['Content-Type'] = getAppConfig().MIME_TYPES.JSON;
        }
    } catch (error) {
        // if the user is not authenticated don't block the client call, just log the error
        console.error('Authentication error => ', error);
    }
    return requestConfig;
};

client.interceptors.request.use(onRequest);

export const buildImportFormData = ({ file, ...rest }: ImportRequest): FormData => {
    const formData = new FormData();
    formData.append('importRequest', new Blob([JSON.stringify(rest)], { type: getAppConfig().MIME_TYPES.JSON }));
    formData.append('file', file);
    return formData;
};
