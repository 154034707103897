import { Employment } from '@/domain/employment/Employment.model';
import { EmploymentDialogDefaultValues } from '@/domain-ui/employment/employment-dialog/EmploymentDialog';
import { getNull } from '@/utils/object.util';
import { mapDepartmentToRecursiveValue } from '@/domain/department/Department.utils';
import { getContractByEmployment, getContracts } from '@/domain/employment/Employment.service';

type MapToEmploymentDefaultValuesArg = Pick<
    Employment,
    'startDate' | 'employmentCreateReason' | 'principal' | 'location' | 'job' | 'jobFamily' | 'managers' | 'department' | 'employmentCostCenters'
> & {
    id?: number;
};
export const mapToEmploymentDefaultValues = (employments: MapToEmploymentDefaultValuesArg[]): EmploymentDialogDefaultValues | undefined => {
    const principalEmployment = employments.find(employment => employment.principal);
    if (!principalEmployment) {
        return undefined;
    }
    const { startDate, employmentCreateReason } = principalEmployment;
    return {
        startDate,
        employmentUpdateReason: employmentCreateReason,
        items: employments.map(mapToEmploymentItem),
    };
};

export const mapToEmploymentItem = (employment: MapToEmploymentDefaultValuesArg): EmploymentDialogDefaultValues['items'][0] => {
    return {
        id: employment.id,
        principal: employment.principal,
        location: employment.location,
        job: employment.job,
        jobFamily: employment.jobFamily ? { id: employment.jobFamily.id } : getNull(),
        managers: employment.managers,
        department: mapDepartmentToRecursiveValue(employment.department),
        employmentCostCenters: employment.employmentCostCenters,
    };
};

/**
 * Get the allowed ranges for the start date to avoid creating an employment outside of contracts
 * @param startDate startDate of the employment when editing
 * @param allEmployments all existing employments
 */
export const getAllowedStartDateRanges = (startDate: LocalDate | undefined, allEmployments: Employment[]): [LocalDate, LocalDate | undefined][] => {
    // related contract when editing an employment
    const relatedContract = startDate ? getContractByEmployment(allEmployments, startDate) : undefined;
    // all existing contract
    const allContracts = getContracts(allEmployments);

    // Get the allowed ranges for the start date to avoid creating an employment outside of contracts
    // or  editing the start date outside the related contract
    return relatedContract ? [[relatedContract.startDate, relatedContract.endDate]] : allContracts.map(contract => [contract.startDate, contract.endDate]);
};
