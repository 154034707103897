import { UnitType } from '@/domain/date/Date.model';
import { LeaveTransaction } from '@/domain/leave-transaction/LeaveTransaction.model';
import { Link, Paper, Stack } from '@mui/material';
import { FC, useState } from 'react';

import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getNestedValueByPath, getSelectFilterNumberValues } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { Document, PreviewData } from '@/domain/document/Document.model';
import { doesEmployeeMatchFilter, isValidEmployeeFilterType } from '@/domain/employee/Employee.service';
import { Employment } from '@/domain/employment/Employment.model';
import { getLeaveRequestAttachmentUrl } from '@/domain/leave-request-attachment/LeaveRequestAttachment.service';
import { convertLeavesMinutesToUnit } from '@/domain/leave-request/LeaveRequest.service';
import { useGetPayrollTransactions } from '@/hooks/payroll/Payroll.hook';
import { DocumentPreviewDialog } from '@/page/document/document-preview-dialog/DocumentPreviewDialog';
import { PayrollLeavesFilter, useGetPayrollLeavesFilters } from '@/page/payroll/PayrollLeavesFilters.hook';
import { formatToLocalDate, getStartOfMonth } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

export const PayrollLeavesPage: FC = () => {
    const { t } = useTranslation();

    const { filters: availableFilters } = useGetPayrollLeavesFilters();
    const [filters, setFilters] = useFiltersStorage('payroll-filters', availableFilters);

    const { dateRangeViewType, dateRange, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'payroll-date-range',
    });

    const { data: payrollTransactions = [], isFetching = true } = useGetPayrollTransactions({
        startDate: getStartOfMonth(dateRange[0]),
    });

    const getTransactionsAfterFilters = () => {
        const clientSideFilters = filters?.filter(filter => filter.key !== 'cycleYear' && filter.key !== 'cycleMonth');
        // if one of the filters is filled, we need to filter the employees
        const filtersFilled = clientSideFilters?.filter(filter => !!filter.value?.length);

        return !filtersFilled?.length
            ? payrollTransactions
            : payrollTransactions.filter(transaction => filtersFilled.every(f => isFilterMatched(f, transaction)));
    };

    const isFilterMatched = (filter: PayrollLeavesFilter, transaction: LeaveTransaction): boolean => {
        const key = filter.key;

        if (isValidEmployeeFilterType(key)) {
            const ids = getSelectFilterNumberValues(filter);
            return !!transaction.leaveRequest?.employee && doesEmployeeMatchFilter(transaction.leaveRequest.employee, ids, key);
        } else {
            const nestedValue = getNestedValueByPath(transaction, key);
            return !!filter.value?.find(option => option.value === nestedValue);
        }
    };

    const agGridWrapper = useAgGridWrapper<LeaveTransaction>();

    const columnDefs: ColDef<LeaveTransaction>[] = [
        {
            field: 'leaveRequest.employee.email',
            headerName: 'Email',
            hide: true,
        },
        {
            field: 'leaveRequest.employee',
            type: 'employee',
            headerName: t('general.employee'),
        },
        {
            field: 'leaveRequest.employee.currentEmployments',
            colId: 'jobTitle',
            headerName: t('payroll.leave_page.job_title'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.map(employment => getLabelTranslation(employment.job.name)).join(', '),
        },
        {
            headerName: t('payroll.leave_page.location'),
            colId: 'location',
            field: 'leaveRequest.employee.currentEmployments',
            valueFormatter: ({ value }: { value: Employment[] }) => value.map(employment => employment.location.name).join(', '),
        },
        {
            headerName: t('payroll.leave_page.department'),
            field: 'leaveRequest.employee.currentEmployments',
            colId: 'department',
            valueFormatter: ({ value }: { value: Employment[] }) => value.map(employment => getLabelTranslation(employment.department.name)).join(', '),
        },
        {
            headerName: t('payroll.leave_page.leave_type'),
            field: 'leaveRequest.leaveType.name',
            type: 'label',
        },
        {
            field: 'leaveRequest.startDate',
            headerName: t('payroll.leave_page.start_date'),
            type: 'date',
        },
        {
            field: 'leaveRequest.endDate',
            headerName: t('payroll.leave_page.end_date'),
            type: 'date',
        },
        {
            headerName: t('general.duration'),
            // Should return a number in days to avoid alphabetical sorting
            valueGetter: ({ data }): number | undefined => {
                if (!data?.durationInDays) {
                    // can be empty in case of medical leave type
                    return;
                }
                return convertLeavesMinutesToUnit({
                    input: data.durationInDays,
                    outputUnit: UnitType.DAYS,
                });
            },
        },
        {
            field: 'leaveRequest.leavePercentage',
            headerName: t('payroll.leave_page.leave_percentage'),
            valueFormatter: ({ value }) => (value ? `${value}%` : ''),
        },
        {
            headerName: t('payroll.leave_page.created_at'),
            field: 'createdAt',
            type: 'date',
            // Needed for export
            valueGetter: ({ data }) => formatToLocalDate(data?.createdAt),
        },
        {
            headerName: t('payroll.leave_page.leave_attachments'),
            field: 'leaveRequest.attachments',
            autoHeight: true,
            // use for export
            valueFormatter: ({ value }: { value: Document[] }) => {
                if (value?.length === 0) {
                    return ' ';
                }
                return value.map(attachment => attachment.name).join(', ');
            },
            cellRenderer: DisplayLeaveAttachmentsCell,
            cellClass: ['display-flex'],
        },

        {
            field: 'leaveRequest.employee.employeeCode',
            headerName: t('payroll.id'),
            hide: true,
        },
    ];

    const rows = getTransactionsAfterFilters();

    const onBtnExport = () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
        });
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack component={Paper} gap={1} p={1} direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction={'row'} alignItems={'flex-start'} gap={1} flexWrap={'wrap'}>
                    <DateRangePicker dates={dateRange} onDatesChanged={onDateRangeChange} defaultViewType={dateRangeViewType} availableViews={['MONTH']} />
                    <FiltersBar filters={filters} onFiltersChange={setFilters} clearable={false} flex={1} />
                </Stack>

                <DatatableAdditionalAction quickFilter={agGridWrapper.quickFilter} onBtnExport={onBtnExport} />
            </Stack>
            <Stack flex={1}>
                <AgGridWrapper<LeaveTransaction> columnDefs={columnDefs} rowData={rows} initRef={agGridWrapper.setGridRef} loading={isFetching} />
            </Stack>
        </Stack>
    );
};

export const DisplayLeaveAttachmentsCell = ({ data }: ICellRendererParams<LeaveTransaction>): ColDef['cellRenderer'] =>
    data ? <DisplayLeaveAttachments attachments={data?.leaveRequest?.attachments} /> : undefined;

const DisplayLeaveAttachments: FC<{ attachments: Document[] | undefined }> = ({ attachments }) => {
    const [previewData, setPreviewData] = useState<PreviewData>();

    if (!attachments) {
        return;
    }

    const handleClickAttachment = async (attachment: Document) => {
        const url = await getLeaveRequestAttachmentUrl(attachment.id, 'INLINE');

        const previewData = {
            document: attachment,
            url: url,
        };

        setPreviewData(previewData);
    };

    const onDownloadClick = async (attachmentId: number) => {
        const url = await getLeaveRequestAttachmentUrl(attachmentId, 'ATTACHMENT');

        // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
        setTimeout(() => {
            window.open(url, '_blank');
        });
    };

    const onClosePreviewDialog = () => {
        setPreviewData(undefined);
    };

    return (
        <Stack direction={'row'} columnGap={1} rowGap={0} flexWrap={'wrap'}>
            {attachments?.map(attachment => {
                return (
                    <Link
                        key={attachment.id}
                        component='button'
                        color={'primary.main'}
                        underline={'always'}
                        onClick={() => handleClickAttachment(attachment)}
                        sx={{ lineHeight: '24px' }}
                    >
                        {attachment?.name}
                    </Link>
                );
            })}
            {!!previewData && (
                <DocumentPreviewDialog
                    onClose={onClosePreviewDialog}
                    previewData={previewData}
                    onDownloadClick={() => onDownloadClick(previewData.document.id)}
                />
            )}
        </Stack>
    );
};
