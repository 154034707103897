import { departmentAPI } from '@/api/department/Department.api';
import {
    Department,
    DepartmentCreationMutation,
    DepartmentNode,
    DepartmentNodeOrganization,
    DepartmentNodesSearchRequest,
    DepartmentUpdateMutation,
} from '@/domain/department/Department.model';
import { employeeAPI } from '@/api/employee/Employee.api';
import { Employee, EmployeeAvatar } from '@/domain/employee/Employee.model';
import { Employment, EmploymentStatus } from '@/domain/employment/Employment.model';
import { OrderMutation } from '@/domain/common';
import { Objective } from '@/domain/objective/Objective.model';
import { employmentAPI } from '@/api/employment/Employment.api';

export function getDepartments(): Promise<Department[]> {
    return departmentAPI.getDepartments();
}

export const getDepartmentNodes = (search: DepartmentNodesSearchRequest = {}): Promise<DepartmentNode[]> => {
    return departmentAPI.getDepartmentNodes(search);
};

export const createDepartment = (mutation: DepartmentCreationMutation): Promise<Department> => {
    return departmentAPI.createDepartment(mutation);
};

export const updateDepartment = (departmentId: number, mutation: DepartmentUpdateMutation): Promise<Department> => {
    return departmentAPI.updateDepartment(departmentId, mutation);
};

export const updateDepartmentOrders = (mutations: OrderMutation[]): Promise<void> => {
    return departmentAPI.updateDepartmentOrders(mutations);
};

export const deleteDepartment = async (departmentId: number): Promise<void> => {
    await departmentAPI.deleteDepartment(departmentId);
};

export const archiveDepartment = async (departmentId: number): Promise<void> => {
    await departmentAPI.archiveDepartment(departmentId);
};

export const unarchiveDepartment = async (departmentId: number): Promise<void> => {
    await departmentAPI.unarchiveDepartment(departmentId);
};

export const getDepartmentNodeOrganizations = async (search: DepartmentNodesSearchRequest = {}): Promise<DepartmentNodeOrganization[]> => {
    const employeeSearch = {
        statuses: [EmploymentStatus.EMPLOYED, EmploymentStatus.HIRED, EmploymentStatus.ON_LONG_LEAVE],
    };
    const [departmentNodes, employees] = await Promise.all([departmentAPI.getDepartmentNodes(search), employeeAPI.searchEmployees(employeeSearch)]);

    if (search.date) {
        const employmentsAtDate = await employmentAPI.searchEmployments({ atDate: search.date });
        return buildDepartmentNodeOrganizationsAtDate(departmentNodes, employees, employmentsAtDate);
    }

    return buildDepartmentNodeOrganizations(departmentNodes, employees);
};

const buildDepartmentNodeOrganizations = (nodes: DepartmentNode[], employees: Employee[]): DepartmentNodeOrganization[] => {
    const employeesMapByDepartment = employees
        .filter(employee => getCurrentDepartments(employee).length > 0) // Ensure the employee has departments
        .flatMap(employee => getCurrentDepartments(employee).map(department => ({ department, employee })))
        .reduce((map, { department, employee }) => {
            if (!map.has(department.id)) {
                map.set(department.id, new Set<EmployeeAvatar>());
            }
            map.get(department.id)?.add(employee);
            return map;
        }, new Map<number, Set<EmployeeAvatar>>());

    return mapEmployeesToDepartmentNodeOrganizations(nodes, employeesMapByDepartment);
};

const buildDepartmentNodeOrganizationsAtDate = (nodes: DepartmentNode[], employees: Employee[], employments: Employment[]): DepartmentNodeOrganization[] => {
    const employeesMapByDepartment = employments.reduce((map, employment) => {
        const employee = employees.find(emp => emp.id === employment.employeeId);
        if (!employee) {
            return map;
        }

        const department = employment.department;
        if (!map.has(department.id)) {
            map.set(department.id, new Set<EmployeeAvatar>());
        }
        map.get(department.id)?.add(employee);
        return map;
    }, new Map<number, Set<EmployeeAvatar>>());

    return mapEmployeesToDepartmentNodeOrganizations(nodes, employeesMapByDepartment);
};

const mapEmployeesToDepartmentNodeOrganizations = (
    nodes: DepartmentNode[],
    employeesMapByDepartment: Map<number, Set<EmployeeAvatar>>,
): DepartmentNodeOrganization[] => {
    return nodes.map(node => {
        const employees = Array.from(employeesMapByDepartment.get(node.id) || []);
        const children = mapEmployeesToDepartmentNodeOrganizations(node.children, employeesMapByDepartment);
        return { ...node, employees, children };
    });
};

const getCurrentDepartments = (employee: Employee): Department[] => {
    return employee.currentEmployments.map(employment => employment.department);
};

/**
 * Retrieves the list of parent departments, including the department itself.
 *
 * @param department - The starting department.
 * @param allDepartments - List of all departments.
 * @param maxDepth - Maximum depth to traverse (default: 6).
 * @returns A list of parent departments including the given department.
 */
export const getParentDepartments = (department: Department | undefined, allDepartments: Department[], maxDepth = 6): Department[] => {
    if (!department || maxDepth <= 0) {
        return [];
    }
    const parent = allDepartments.find(dept => dept.id === department.parentId);
    return [department, ...getParentDepartments(parent, allDepartments, maxDepth - 1)];
};

/**
 * Retrieves available objectives for an employee in a given department.
 *
 * @param employeeDepartment - The department of the employee.
 * @param objectives - A list of all objectives.
 * @param allDepartments - A list of all departments.
 * @returns A list of objectives available within the allowed department hierarchy.
 */
export const getDepartmentsObjectivesAvailable = (employeeDepartment: Department, objectives: Objective[], allDepartments: Department[]): Objective[] => {
    // Retrieve the allowed parent departments (including the employee's own department)
    const allowedDepartments = getParentDepartments(employeeDepartment, allDepartments).map(dept => dept.id);
    // Filter objectives to include only those belonging to the allowed departments
    return objectives.filter(objective => objective.department && allowedDepartments.includes(objective.department.id));
};
