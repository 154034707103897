import { EmployeeAvatarWithDetails } from '@/components/employee-avatar/EmployeeAvatarWithDetails';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { ApproveTimesheetMutation, DeclineTimesheetMutation, PendingDayTimesheet, Timesheet, TimesheetAction } from '@/domain/timesheet/Timesheet.model';
import { approveTimesheet, cancelPendingTimesheet } from '@/domain/timesheet/Timesheet.service';
import { useGetPendingTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import { TimesheetPendingFilter, useTimesheetsPendingPageFilters } from '@/hooks/timesheet/TimesheetsPendingPageFilters.hook';
import { calculateDurationWithBreakFromHoursMinutes } from '@/page/employee-timesheet/EmployeeTimesheet.util';
import { ApprovePendingTimesheetsDialog } from '@/page/timesheet/approve-pending-timesheet-dialog/ApprovePendingTimesheetsDialog';
import { ClockInOutStatusIconizedInfoTooltip } from '@/page/timesheet/clock-in-out-status-iconized-info-tooltip/ClockInOutStatusIconizedInfoTooltip';
import { TimesheetDialog } from '@/page/timesheet/timesheet-dialog/TimesheetDialog';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { differenceInMinutes, formatDurationInHours, formatInDefaultWeekName, getTimeFormatFromDate } from '@/utils/datetime.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Button, Divider, List, ListItem, ListItemButton, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AlertCircleIcon, Cancel01Icon, Tick02Icon } from 'hugeicons-react';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { doesEmployeeMatchFilter, isValidEmployeeFilterType } from '@/domain/employee/Employee.service';
import { getNestedValueByPath, getSelectFilterNumberValues } from '@/components/filters-bar/FiltersBar.util';

export const TimesheetsPendingPage: FC = () => {
    const { t } = useTranslation();
    const [timesheetDialogDayTimesheet, setTimesheetDialogDayTimesheet] = useState<PendingDayTimesheet>();
    const {
        data: pendingDayTimesheets,
        isLoading: isDayTimesheetLoading,
        isError,
        refetch: refetchPendingTimesheets,
        error: pendingTimesheetsError,
    } = useGetPendingTimesheets();

    const INITIAL_DISPLAY_LIMIT = 10;
    const INCREMENT_DISPLAY_LIMIT = 10;
    const [numberOfRowsToDisplay, setNumberOfRowsToDisplay] = useState<number>(INITIAL_DISPLAY_LIMIT);

    const realm = useAppSelector(state => state.ui.currentRealm);
    const isPlanningActive = realm ? hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.PLANNING) : false;

    const [filters, setFilters] = useTimesheetsPendingPageFilters(pendingDayTimesheets ?? [], !!pendingDayTimesheets);

    const isFilterMatched = (filter: TimesheetPendingFilter, pendingTimesheet: PendingDayTimesheet) => {
        const key = filter.key;
        if (isValidEmployeeFilterType(key)) {
            return doesEmployeeMatchFilter(pendingTimesheet.employee, getSelectFilterNumberValues(filter), key);
        } else if (filter.key === 'AREA_IDS') {
            const pendingTimesheetAreaIds = pendingTimesheet?.timesheets?.map(timesheet => timesheet?.area?.id).filter(areaId => areaId !== undefined) ?? [];
            return getSelectFilterNumberValues(filter).some(filterId => pendingTimesheetAreaIds.includes(filterId));
        } else {
            const valueFromEmployee = getNestedValueByPath(pendingTimesheet, filter.key);
            return filter.value?.find(option => option.value === valueFromEmployee);
        }
    };

    const filteredPendingDayTimesheets =
        pendingDayTimesheets?.filter(dayTimesheet => {
            return filters.filter(filter => filter?.value).every(filter => isFilterMatched(filter, dayTimesheet));
        }) ?? [];

    const hasMorePages = () => {
        const count = filteredPendingDayTimesheets?.length - numberOfRowsToDisplay;
        if (count >= 0) {
            return count;
        } else {
            return 0;
        }
    };

    //set the timesheets to approve and remove the ones that are missing the end date
    const timesheetsToApprove = filteredPendingDayTimesheets
        ?.filter(dayTimesheet => !dayTimesheet.timesheets.some(timesheet => !timesheet?.endAt))
        .flatMap(dayTimesheet => dayTimesheet.timesheets);

    const canApproveAllTimesheets =
        filteredPendingDayTimesheets?.filter(dayTimesheet => !dayTimesheet.timesheets.some(timesheet => !timesheet?.endAt))?.length > 0;

    const handleOnApprove = async () => {
        showSnackbar(t('pending_timesheets_page.approved_timesheet'), 'success');
        if (refetchPendingTimesheets) {
            await refetchPendingTimesheets();
        }
    };

    return (
        <Stack direction='column' p={2.5} component={Paper} gap={2} flex={1}>
            <StateHandler isLoading={isDayTimesheetLoading} isError={isError} error={pendingTimesheetsError}>
                <Stack gap={1}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Stack direction='row' alignItems='flex-end' gap={1}>
                            <FiltersBar filters={filters} onFiltersChange={setFilters} />
                        </Stack>
                        <Stack direction='row' alignItems='center'>
                            {!canApproveAllTimesheets && filteredPendingDayTimesheets?.length > 0 && (
                                <Tooltip title={t('pending_timesheets_page.error_no_end_date_on_approve_all')}>
                                    <AlertCircleIcon color='error' />
                                </Tooltip>
                            )}
                            <ApprovePendingTimesheetsDialog timesheetsToApprove={timesheetsToApprove} onApprove={handleOnApprove}>
                                <Button disabled={!canApproveAllTimesheets}>{t('pending_timesheets_page.approve_all_timesheet')}</Button>
                            </ApprovePendingTimesheetsDialog>
                        </Stack>
                    </Stack>
                    <Stack component={List} flex={1} divider={<Divider />} disablePadding>
                        {filteredPendingDayTimesheets?.slice(0, numberOfRowsToDisplay).map(dayTimesheet => (
                            <ListItem role='li' key={dayTimesheet.date.toString() + '_' + dayTimesheet?.employee?.id} disablePadding>
                                <Stack
                                    component={ListItemButton}
                                    direction='row'
                                    justifyContent='space-between'
                                    alignItems='top'
                                    alignSelf='stretch'
                                    onClick={() => {
                                        setTimesheetDialogDayTimesheet(dayTimesheet);
                                    }}
                                    flexWrap='wrap'
                                    p={0}
                                    disableRipple
                                >
                                    <PendingTimesheetElement
                                        refetchPendingTimesheets={async () => {
                                            await refetchPendingTimesheets();
                                        }}
                                        pendingDayTimesheet={dayTimesheet}
                                        isPlanningActive={isPlanningActive}
                                    />
                                </Stack>
                            </ListItem>
                        ))}
                    </Stack>
                    {hasMorePages() >= 1 && (
                        <Stack textAlign={'right'}>
                            <Button
                                variant='text'
                                size='small'
                                onClick={() => {
                                    setNumberOfRowsToDisplay(prev => prev + INCREMENT_DISPLAY_LIMIT);
                                }}
                            >
                                {t('pending_timesheets_page.show_more_rows', {
                                    numberOfRows: hasMorePages(),
                                })}
                            </Button>
                        </Stack>
                    )}

                    {!!timesheetDialogDayTimesheet && timesheetDialogDayTimesheet?.employee && (
                        <TimesheetDialog
                            defaultReferenceDate={timesheetDialogDayTimesheet.date}
                            missingEntriesDates={[]}
                            mode={TimesheetAction.EDIT}
                            open={true}
                            employee={timesheetDialogDayTimesheet.employee}
                            onClose={() => {
                                setTimesheetDialogDayTimesheet(undefined);
                            }}
                            onSave={async () => {
                                setTimesheetDialogDayTimesheet(undefined);
                                if (refetchPendingTimesheets) {
                                    await refetchPendingTimesheets();
                                }
                            }}
                        />
                    )}
                </Stack>
            </StateHandler>
        </Stack>
    );
};

type PendingTimesheetElementProps = {
    pendingDayTimesheet: PendingDayTimesheet;
    refetchPendingTimesheets: () => Promise<void>;
    isPlanningActive: boolean;
};

const PendingTimesheetElement: FC<PendingTimesheetElementProps> = ({ pendingDayTimesheet, refetchPendingTimesheets, isPlanningActive }) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOnCancel = async () => {
        if (!pendingDayTimesheet?.employee) {
            return;
        }
        const declineRequest: DeclineTimesheetMutation = {
            employeeId: pendingDayTimesheet?.employee?.id,
            timesheetIds: pendingDayTimesheet.timesheets.map(timesheet => {
                return timesheet.id;
            }),
        };
        try {
            await cancelPendingTimesheet(declineRequest);
            await refetchPendingTimesheets();
            showSnackbar(t('pending_timesheets_page.canceled_timesheet'), 'success');
            await refetchPendingTimesheets();
        } catch (error) {
            handleError(error);
        }
    };

    const handleOnApprove = async () => {
        if (!pendingDayTimesheet?.employee) {
            return;
        }
        const approveRequest: ApproveTimesheetMutation = {
            employeeId: pendingDayTimesheet?.employee?.id,
            timesheetIds: pendingDayTimesheet.timesheets.map(timesheet => {
                return timesheet.id;
            }),
        };
        try {
            await approveTimesheet(approveRequest);
            await refetchPendingTimesheets();
            showSnackbar(t('pending_timesheets_page.approved_timesheet'), 'success');
        } catch (error) {
            handleError(error);
        }
    };

    const isEndDateMissing = pendingDayTimesheet?.timesheets.some(timesheet => !timesheet.endAt);

    return (
        <Stack justifyContent={'space-between'} direction='row' width={'100%'}>
            <Stack useFlexGap flexWrap='wrap' direction='row' overflow={'hidden'} width={'100%'}>
                <Stack
                    direction='row'
                    alignItems={isMobile ? 'center' : undefined}
                    gap={1}
                    paddingTop={2}
                    paddingLeft={0}
                    paddingBottom={isMobile ? 0 : 2}
                    justifyContent='space-between'
                    width={isMobile ? '100%' : undefined}
                >
                    <Stack justifyContent='start' width={'200px'}>
                        {pendingDayTimesheet?.employee && <EmployeeAvatarWithDetails employee={pendingDayTimesheet.employee} />}
                    </Stack>

                    {isMobile && (
                        <ButtonsToApproveDeclineTimesheets onCancel={handleOnCancel} onApprove={handleOnApprove} canApproveTimesheet={!isEndDateMissing} />
                    )}
                </Stack>

                <Stack
                    direction='column'
                    flex={1}
                    alignItems='flex-start'
                    px={2}
                    py={isMobile ? 1 : 2}
                    minWidth={'200px'}
                    maxWidth={isPlanningActive ? '400px' : undefined}
                    overflow={'hidden'}
                >
                    <Typography variant='body2bold' color='textPrimary'>
                        {formatInDefaultWeekName(pendingDayTimesheet.date) + ' · ' + getTimesheetDuration(pendingDayTimesheet.timesheets)}
                    </Typography>
                    {pendingDayTimesheet.timesheets.map(timesheet => (
                        <TimesheetDisplayElement isMobile={isMobile} key={'timesheetPendingPageElement' + timesheet.id} timesheet={timesheet} />
                    ))}
                </Stack>

                {pendingDayTimesheet.plannedTimesheets.length > 0 && (
                    <Stack direction='column' flex={1} alignItems='flex-start' px={2} pt={isMobile ? 1 : 2} pb={2} minWidth={'300px'} overflow={'hidden'}>
                        <Typography variant='body2bold' color='textPrimary'>
                            {t('pending_timesheets_page.planned_timesheets') + ' · ' + getTimesheetDuration(pendingDayTimesheet.plannedTimesheets)}
                        </Typography>
                        {pendingDayTimesheet.plannedTimesheets.map(timesheet => (
                            <TimesheetDisplayElement isMobile={isMobile} key={'timesheetPendingPageElement' + timesheet.id} timesheet={timesheet} />
                        ))}
                    </Stack>
                )}
            </Stack>
            {!isMobile && <ButtonsToApproveDeclineTimesheets onCancel={handleOnCancel} onApprove={handleOnApprove} canApproveTimesheet={!isEndDateMissing} />}
        </Stack>
    );
};

type TimesheetDisplayElementProps = {
    timesheet: Timesheet;
    isMobile: boolean;
};
const TimesheetDisplayElement: FC<TimesheetDisplayElementProps> = ({ timesheet, isMobile }) => {
    const realm = useAppSelector(state => state.ui.currentRealm);
    const { t } = useTranslation();

    const getTimesheetDisplay = (timesheet: Timesheet): string => {
        const startTime = getTimeFormatFromDate(timesheet.startAt);
        const endTime = timesheet.endAt ? getTimeFormatFromDate(timesheet.endAt) : undefined;
        const endLabel = timesheet.endAt ? getTimeFormatFromDate(timesheet.endAt) : t('pending_timesheets_page.missing_clock_out');
        const breakDuration = timesheet?.breakDuration ? `(${timesheet?.breakDuration})` : '';
        const area = timesheet?.area?.name ? '· ' + timesheet?.area?.name : '';
        const comment = timesheet?.comment && !isMobile ? '· ' + timesheet?.comment : '';
        const timesheetMode = timesheet?.timesheetSetting?.timesheetMode ?? TimesheetMode.NORMAL;

        const time = `${startTime} → ${endLabel} ${breakDuration}`;
        const title = timesheetMode === TimesheetMode.NORMAL ? time : calculateDurationWithBreakFromHoursMinutes(startTime, endTime, timesheet.breakDuration);

        return `${title} ${area} ${comment}`;
    };

    return (
        <Fragment key={timesheet.id}>
            {realm && <ClockInOutStatusIconizedInfoTooltip timesheet={timesheet} valueToDisplay={getTimesheetDisplay(timesheet)} realm={realm} />}
        </Fragment>
    );
};

type ButtonsToApproveDeclineTimesheetsProps = {
    onCancel: () => void;
    onApprove: () => void;
    canApproveTimesheet?: boolean;
};
const ButtonsToApproveDeclineTimesheets: FC<ButtonsToApproveDeclineTimesheetsProps> = ({ onCancel, onApprove, canApproveTimesheet }) => {
    return (
        <Stack direction='row' alignItems='center' gap={1}>
            <Button
                variant='contained'
                color='inherit'
                sx={{ paddingX: 0.8, minWidth: 'auto' }}
                onClick={event => {
                    event.stopPropagation();
                    onCancel();
                }}
                arial-label='cancel-timesheet'
            >
                <Cancel01Icon />
            </Button>

            <Stack>
                <Button
                    disabled={!canApproveTimesheet}
                    variant='contained'
                    sx={{ paddingX: 0.8, minWidth: 'auto' }}
                    onClick={event => {
                        event.stopPropagation();
                        onApprove();
                    }}
                    arial-label='approve-timesheet'
                >
                    <Tick02Icon />
                </Button>
            </Stack>
        </Stack>
    );
};
const getTimesheetDuration = (timesheets: Timesheet[]): string => {
    const totalTime = timesheets.reduce((accumulator, timesheet) => {
        if (timesheet.endAt) {
            return accumulator + differenceInMinutes(timesheet.endAt, timesheet.startAt) - timesheet.breakDuration;
        }
        return accumulator;
    }, 0);

    return formatDurationInHours(totalTime);
};
