import { RealmFeature } from '@/domain/realm/Realm.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import {
    BankIcon as PayrollIcon,
    Calendar03Icon as LeavesIcon,
    CheckmarkBadge02Icon,
    Clock01Icon as TimesheetsIcon,
    DashboardSquare02Icon as PlanningIcon,
    DocumentValidationIcon,
    File01Icon as DocumentIcon,
    Home03Icon as HomeIcon,
    Layout3ColumnIcon as ReportIcon,
    Settings02Icon,
    StarIcon as ReviewsIcon,
    Target01Icon as ObjectivesIcon,
    UserIcon as ProfileIcon,
    UserMultiple02Icon as EmployeesIcon,
    AnalyticsUpIcon,
} from 'hugeicons-react';

import {
    canAccessOtherEmployeeReviews,
    canConfigureCompanySettings,
    canConfigureReview,
    canManageOtherEmployeeObjectives,
    canManageOtherEmployeeTimesheets,
    canManagePayroll,
    canSeeOtherEmployeeLeaves,
    canSeeSurveyResults,
    canViewCompanyDocuments,
    canViewDashboard,
    canViewEmployeesDirectory,
    canViewOtherTimesheetInsightBalance,
    canViewReports,
    canViewReviews,
    canViewShifts,
} from '@/domain/permission/Permission.service';
import { useGetProfilePages } from '@/page/employee-profile/EmployeeProfilePages.hook';
import { getObjectivesPages } from '@/page/objective/objectives-page/ObjectivePages';
import { getHomePages, homePage } from '@/page/home/pending/HomePages';
import { getPeoplePages } from '@/page/people/PeoplesPages';
import { getLeavePages } from '@/page/leave/LeavePages';
import { getPlanningPages } from '@/page/planning/PlanningPages';
import { getTimesheetPages } from '@/page/timesheet/TimesheetPages';
import { getReviewPages } from '@/page/review/ReviewPages';
import { getReportPages } from '@/page/report/ReportPages';
import { getPayrollPages } from '@/page/payroll/PayrollPages';
import { getDocumentPages } from '@/page/document/DocumentPages';
import { getSurveyPages } from '@/page/survey/SurveyPages';
import { getSettingsPages } from '@/page/setting/CompanySettingsPages';
import { getEmployeeSkillPages } from '@/page/employee-skill/EmployeeSkillPages';
import { useCurrentEmployee, useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { getDashboardPages } from '@/page/dashboard/DashboardPages';

export type Page = {
    path: string;
    labelKey: string;
    condition?: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => boolean;
};

export type PagesGroupConfig = {
    pages: Page[];
    icon: JSX.Element;
    labelKey: string;
    canSee: boolean;
};

export const myProfileSidebarLabelKey = 'sidebar.my_profile';
export const managePeopleSidebarLabelKey = 'sidebar.manage_people';

// TODO : make all function to get pages as a hook https://rogerhr.atlassian.net/browse/RP-5963
export const useGetPagesGroupsConfig = (): PagesGroupConfig[] => {
    const realm = useCurrentRealm();
    const realmFeatures = realm.realmFeatures;
    const profilePages = useGetProfilePages();

    const employeeId = useCurrentEmployee().id;
    const policies = useCurrentPolicies();

    return [
        {
            pages: getHomePages(),
            icon: <HomeIcon />,
            labelKey: homePage.labelKey,
            canSee: true,
        },
        {
            pages: profilePages,
            icon: <ProfileIcon />,
            labelKey: myProfileSidebarLabelKey,
            canSee: true,
        },
        {
            pages: getPeoplePages(employeeId, realmFeatures, policies),
            icon: <EmployeesIcon />,
            labelKey: managePeopleSidebarLabelKey,
            canSee: canViewEmployeesDirectory(policies, employeeId),
        },
        {
            pages: getLeavePages(employeeId, realmFeatures, policies),
            icon: <LeavesIcon />,
            labelKey: 'sidebar.manage_leaves',
            canSee: canSeeOtherEmployeeLeaves(realmFeatures, policies, employeeId),
        },
        {
            pages: getPlanningPages(),
            // PlanningIcon size seams to be wrong, so we use size 23
            icon: <PlanningIcon size={23} />,
            labelKey: 'sidebar.planning',
            canSee: canViewShifts(realmFeatures, policies),
        },
        {
            pages: getTimesheetPages(employeeId, realmFeatures, policies),
            icon: <TimesheetsIcon />,
            labelKey: 'sidebar.timesheets',
            canSee:
                canManageOtherEmployeeTimesheets(realmFeatures, policies, employeeId) ||
                canViewOtherTimesheetInsightBalance(realmFeatures, policies, employeeId),
        },
        {
            pages: getDocumentPages(),
            icon: <DocumentIcon />,
            labelKey: 'sidebar.company_documents',
            canSee: canViewCompanyDocuments(realmFeatures, policies),
        },
        {
            pages: getSurveyPages(realmFeatures, policies),
            icon: <DocumentValidationIcon />,
            labelKey: 'sidebar.surveys',
            canSee: canSeeSurveyResults(realmFeatures, policies),
        },
        {
            pages: getReviewPages(realmFeatures, policies),
            icon: <ReviewsIcon />,
            labelKey: 'sidebar.reviews',
            canSee: canAccessOtherEmployeeReviews(realmFeatures, policies, employeeId) || canViewReviews(realmFeatures, policies),
        },
        {
            pages: getObjectivesPages(),
            icon: <ObjectivesIcon />,
            labelKey: 'sidebar.objectives',
            canSee: canManageOtherEmployeeObjectives(realmFeatures, policies, employeeId),
        },
        {
            pages: getEmployeeSkillPages(employeeId, realmFeatures, policies),
            icon: <CheckmarkBadge02Icon />,
            labelKey: 'sidebar.employee_skills',
            canSee: canConfigureReview(realmFeatures, policies),
        },
        {
            pages: getPayrollPages(realmFeatures, policies, employeeId),
            icon: <PayrollIcon />,
            labelKey: 'sidebar.payroll',
            canSee: canManagePayroll(realmFeatures, policies, employeeId),
        },
        {
            pages: getReportPages(),
            icon: <ReportIcon />,
            labelKey: 'sidebar.reports',
            canSee: canViewReports(realmFeatures, policies),
        },
        {
            pages: getDashboardPages(),
            icon: <AnalyticsUpIcon />,
            labelKey: 'sidebar.dashboard',
            canSee: canViewDashboard(policies, employeeId),
        },
        {
            pages: getSettingsPages(),
            icon: <Settings02Icon />,
            labelKey: 'sidebar.company_settings',
            canSee: canConfigureCompanySettings(policies),
        },
    ].filter(({ canSee }) => canSee);
};
