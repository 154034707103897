import { Announcement, CreateAnnouncementMutation, EditAnnouncementMutation } from '@/domain/announcement/Announcement.model';
import {
    createAnnouncement,
    deleteAnnouncementImage,
    editAnnouncement,
    editAnnouncementImage,
    getAnnouncementImageUrl,
    getAnnouncements,
    getAnnouncementsAsEditor,
} from '@/domain/announcement/Announcement.service';
import { createQueryHook, UseMutationResult } from '@/page/Query.type';
import { useCallback, useState } from 'react';

export const useGetAnnouncements = createQueryHook('announcements', getAnnouncements);

export const useGetAnnouncementsAsEditor = createQueryHook('announcementsAsEditor', getAnnouncementsAsEditor);

export const useCreateAnnouncement = (): UseMutationResult<Announcement, CreateAnnouncementMutation> => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const mutate = useCallback(async (mutationVariables: CreateAnnouncementMutation) => {
        setIsPending(true);
        const { image } = mutationVariables;
        try {
            const newAnnouncement = await createAnnouncement(mutationVariables);
            if (image) {
                await editAnnouncementImage(newAnnouncement.id, image);
            }
            return newAnnouncement;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setIsPending(false);
        }
    }, []);

    return {
        mutate,
        isPending,
        isError: !!error,
        error,
    };
};

export const useEditAnnouncement = (): UseMutationResult<Announcement, EditAnnouncementMutation> => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const mutate = useCallback(async (mutationVariables: EditAnnouncementMutation) => {
        const { id: announcementId, image } = mutationVariables;
        setIsPending(true);
        try {
            const updatedAnnouncement = await editAnnouncement(mutationVariables);
            if (image) {
                const { imageUrl: newImageUrl } = await editAnnouncementImage(announcementId, image);
                updatedAnnouncement.imageUrl = newImageUrl;
            }
            // delete image if announcement had image and hasn't after edit
            if (!image && updatedAnnouncement.imageUrl) {
                await deleteAnnouncementImage(announcementId);
                updatedAnnouncement.imageUrl = undefined;
            }
            return updatedAnnouncement;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setIsPending(false);
        }
    }, []);

    return {
        mutate,
        isPending,
        isError: !!error,
        error,
    };
};

export const useGetAnnouncementImageUrl = createQueryHook('announcementImageUrl', getAnnouncementImageUrl);
