import { getAvatarSizeProps } from '@/components/employee-avatar/EmployeeAvatar.util';
import { EmployeeAvatar as EmployeeAvatarType } from '@/domain/employee/Employee.model';
import { useGetEmployeeAvatarUrl } from '@/hooks/employee/Employee.hook';
import { Avatar, AvatarProps, Link, LinkProps, Tooltip } from '@mui/material';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router';

export type EmployeeAvatarProps = AvatarProps & {
    size?: 'small' | 'medium' | 'large' | 'xl';
    employeeAvatar: EmployeeAvatarType;
    profileLink?: boolean;
    disableTooltip?: boolean;
};

export const EmployeeAvatar = forwardRef<HTMLDivElement, EmployeeAvatarProps>(
    ({ employeeAvatar, size = 'medium', profileLink, disableTooltip, sx, ...rest }, ref) => {
        // We are doing a request if the avatarImageUrl is not empty
        const { data: avatarUrl } = useGetEmployeeAvatarUrl(employeeAvatar.id, { options: { enabled: !!employeeAvatar.avatarImageUrl } });

        if (!employeeAvatar.id) {
            return <></>;
        }

        const display = getFirstLetters(employeeAvatar.displayName);
        const alt = employeeAvatar.displayName;
        const sizeProps = getAvatarSizeProps(size);
        const tooltipTitle = disableTooltip ? '' : employeeAvatar.displayName;

        const avatarProps = {
            ref,
            alt,
            src: avatarUrl,
            sx: {
                ...sizeProps,
                ...sx,
            },
            ...rest,
            children: display,
        };

        // when profileLink is enabled, Tooltip is inside the Link, otherwise, Tooltip does not work
        if (profileLink) {
            return (
                <Avatar component={ProfileLink} employeeId={employeeAvatar.id} tooltipTitle={tooltipTitle} {...avatarProps}>
                    {display}
                </Avatar>
            );
        }

        return (
            <Tooltip title={tooltipTitle}>
                <Avatar {...avatarProps} />
            </Tooltip>
        );
    },
);
EmployeeAvatar.displayName = 'EmployeeAvatar';

type ProfileLinkProps = LinkProps & { employeeId: number; tooltipTitle: string };
const ProfileLink = forwardRef<HTMLAnchorElement, ProfileLinkProps>((props, ref) => {
    const { employeeId, tooltipTitle, children, ...rest } = props;
    const PROFILE_LINK = `/profile/${employeeId}`;

    return (
        <Link component={RouterLink} {...rest} to={PROFILE_LINK} underline={'none'} ref={ref}>
            <Tooltip title={tooltipTitle}>
                <span>{children}</span>
            </Tooltip>
        </Link>
    );
});

ProfileLink.displayName = 'ProfileLink';

const getFirstLetters = (displayName: string) => {
    if (!displayName) {
        return '';
    }

    return displayName
        .split(' ')
        .filter(word => word.length > 0)
        .map(word => word.charAt(0).toUpperCase())
        .join('');
};
