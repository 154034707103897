import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey, getSelectFilterStringValuesByKey, getTimeRangeFilterValueByKey } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { EmployeeTimeBalance, TimesheetInsightSearch } from '@/domain/timesheet-insight/TimesheetInsight.model';
import { useGetEmployeeTimeBalances } from '@/hooks/timesheet-insight/TimesheetInsight.hook';
import { useTimesheetInsightsTableFilters } from '@/hooks/timesheet/TimesheetInsightsTableFilters.hook';
import { DurationUnit } from '@/i18n/i18n';
import { DayOfWeek } from '@/utils/datetime.util';
import { ColDef } from 'ag-grid-community';
import { Paper } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterType } from '@/components/filters-bar/FilterBar.type';

export const TimesheetsInsightPage: FC = () => {
    const { t } = useTranslation();
    const { filters: availableFilters } = useTimesheetInsightsTableFilters();
    const [filters, setFilters] = useFiltersStorage('timesheets-insights-filters', availableFilters);
    const { dateRangeViewType, dateRange, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'timesheets-insights-date-range',
    });

    const { gridRef, setGridRef, quickFilter } = useAgGridWrapper<EmployeeTimeBalance>();

    const mapFiltersToSearch = (filters: FilterType[]): TimesheetInsightSearch => {
        const [startTime, endTime] = getTimeRangeFilterValueByKey(filters, 'timeRange') ?? [];

        return {
            startDate: dateRange[0],
            endDate: dateRange[1],
            dayOfWeek: getSelectFilterStringValuesByKey(filters, 'dayOfWeek')?.[0] as DayOfWeek,
            startTime,
            endTime,
            locationIds: getFilterValueIdsByKey(filters, 'locations'),
            departmentIds: getFilterValueIdsByKey(filters, 'departments'),
            jobIds: getFilterValueIdsByKey(filters, 'jobs'),
        };
    };

    const { data: employeeTimeBalances = [], isFetching: isFetchingEmployeeTimeBalances = false } = useGetEmployeeTimeBalances(mapFiltersToSearch(filters), {
        enabled: !!filters.length,
    });

    const columnDefs: ColDef<EmployeeTimeBalance>[] = [
        {
            field: 'employee.email',
            headerName: 'Email',
            hide: true,
        },
        {
            field: 'employee.employeeCode',
            headerName: t('payroll.id'),
            hide: true,
        },
        {
            field: 'employee',
            type: 'employee',
            headerName: t('general.employee'),
        },
        {
            field: 'totalWorkedDays',
            headerName: t('timesheets.insights_page.worked_days'),
            type: 'string',
        },
        {
            field: 'totalWorkedCount',
            headerName: t('timesheets.insights_page.total_worked_time'),
            valueGetter: ({ data: employeeTimeBalance }) => (employeeTimeBalance?.totalWorkedCount ?? 0) / 60,
            valueFormatter: ({ value }) =>
                value
                    ? t('duration.formatDuration', {
                          duration: value,
                          unit: DurationUnit.HOURS,
                      })
                    : '-',
        },
        {
            field: 'totalPublicHolidayCount',
            headerName: t('timesheets.insights_page.total_public_holiday_count'),
            valueGetter: ({ data: employeeTimeBalance }) => (employeeTimeBalance?.totalPublicHolidayCount ?? 0) / 60,
            valueFormatter: ({ value }) =>
                value
                    ? t('duration.formatDuration', {
                          duration: value,
                          unit: DurationUnit.HOURS,
                      })
                    : '-',
        },
    ];

    const handleDownloadData = () => {
        gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
        });
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack component={Paper} direction={'row'} alignItems={'center'} justifyContent={'space-between'} p={1}>
                <Stack direction={'row'} alignItems={'flex-start'} gap={1} flexWrap={'wrap'}>
                    <DateRangePicker
                        dates={dateRange}
                        onDatesChanged={onDateRangeChange}
                        defaultViewType={dateRangeViewType}
                        availableViews={['MONTH', 'RANGE']}
                    />
                    <FiltersBar filters={filters} onFiltersChange={setFilters} flex={1} />
                </Stack>

                <DatatableAdditionalAction
                    quickFilter={quickFilter}
                    onBtnExport={handleDownloadData}
                    disabled={!employeeTimeBalances.length || isFetchingEmployeeTimeBalances}
                />
            </Stack>

            <Stack flex={1}>
                <AgGridWrapper columnDefs={columnDefs} rowData={employeeTimeBalances} initRef={setGridRef} compact loading={isFetchingEmployeeTimeBalances} />
            </Stack>
        </Stack>
    );
};
