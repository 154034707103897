import { canViewAtLeastOneProfileInfoTab } from '@/domain/permission/Permission.service';
import { useGetSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';
import { useGetProfilePages } from '@/page/employee-profile/EmployeeProfilePages.hook';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { useEmployeeProfileTab } from '@/page/employee-profile/useEmployeeProfileTab';
import { useCurrentEmployee, useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface EmployeeProfileLayoutProps {
    tabs: ReactNode;
    content: ReactNode;
    profile: ReactNode;
    mobileProfile: ReactNode;
}

export const EmployeeProfileLayout: FC<EmployeeProfileLayoutProps> = ({ tabs, content, profile, mobileProfile }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();
    const currentEmployee = useCurrentEmployee();
    const employeeId = useEmployeeProfileId();

    const navigate = useNavigate();
    const isCurrentEmployee = currentEmployee?.id === employeeId;

    const { data: sectionDefinitions = [] } = useGetSectionDefinitions();
    const profileTabsItems = useGetProfilePages(employeeId);

    const employeeProfileTab = useEmployeeProfileTab();
    const requestedTab = `/profile/${employeeProfileTab}`;
    // Redirect to the first valid tab if the requested tab is not valid
    useDeepCompareEffect(() => {
        const isTabValid = profileTabsItems.some(item => item.path === requestedTab);

        if (!isTabValid) {
            const firstValidTab = profileTabsItems[0];
            // Redirect to the first valid tab
            // if no valid tab is found, tabs will be hidden. See the condition below canSeeAtLeastOneTab
            if (firstValidTab) {
                const firstValidTabWithoutProfile = firstValidTab.path.replace('/profile/', '');
                const employeePath = employeeId ? `${employeeId}/` : '';
                navigate(`/profile/${employeePath}${firstValidTabWithoutProfile}`, { replace: true });
            }
        }
    }, [employeeId, location, navigate, policies, profileTabsItems, realm]);

    const PROFILE_WIDTH = 320;
    const canSeeAtLeastOneTab = canViewAtLeastOneProfileInfoTab(
        realm.realmFeatures ?? [],
        policies,
        employeeId,
        sectionDefinitions.map(({ id }) => id),
    );
    const showProfileCard = !isMobile || !canSeeAtLeastOneTab;
    const showMobileProfileCard = isMobile && !isCurrentEmployee;

    return (
        <Stack
            flex='1'
            direction={isMobile ? 'column' : 'row'}
            gap={2}
            // This is to disable the scroll from the main layout on desktop
            // overflow is set to hidden and it works because the parent direct has overflowY: 'auto'
            overflow={isMobile ? undefined : 'hidden'}
        >
            {showProfileCard && (
                // the key is to force the component to re-render when the employeeId changes
                <Stack
                    key={employeeId}
                    minWidth={PROFILE_WIDTH}
                    maxWidth={canSeeAtLeastOneTab ? PROFILE_WIDTH : undefined}
                    flexGrow={canSeeAtLeastOneTab ? 0 : 1}
                >
                    {profile}
                </Stack>
            )}
            {canSeeAtLeastOneTab && (
                /* we need to set the width so that we can have horizontal scroll in the employee leaves page */
                <Stack flex='1' gap={2} width={isMobile ? '100%' : `calc(100% - ${PROFILE_WIDTH}px - 16px)`}>
                    {!isMobile && tabs}
                    {showMobileProfileCard && mobileProfile}
                    <Stack
                        flex={1}
                        sx={{
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': { display: 'none' },
                        }}
                    >
                        {content}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
