import { DayOfWeek, MONTHS } from '@/utils/datetime.util';

export enum TimesheetMode {
    SIMPLIFIED = 'SIMPLIFIED',
    NORMAL = 'NORMAL',
}

export const TimesheetMissingModeArray = ['SHOW_MISSING', 'COUNT_AS_UNPAID_LEAVES', 'SHOW_MISSING_AND_COUNT_AS_UNPAID_LEAVES'] as const;
export type TimesheetMissingMode = (typeof TimesheetMissingModeArray)[number];

export type TimesheetSetting = {
    id: number;
    tenantId: string;
    cycleStartMonth: MONTHS;
    name: string;
    timesheetMode: TimesheetMode;
    autofillTimesheet: boolean;
    countDailyDifference: boolean;
    includeShiftsIntoTimesheets: boolean;
    allowFutureInputs: boolean;
    nightBonusPercentage: number;
    nightBonusStartTime: string;
    nightBonusEndTime: string;
    saturdayBonusPercentage: number;
    saturdayBonusStartTime: string;
    saturdayBonusEndTime: string;
    saturdayFromDayOfWeek: DayOfWeek;
    saturdayToDayOfWeek: DayOfWeek;
    sundayBonusPercentage: number;
    sundayBonusStartTime: string;
    sundayBonusEndTime: string;
    sundayFromDayOfWeek: DayOfWeek;
    sundayToDayOfWeek: DayOfWeek;
    mandatoryComment: boolean;
    breakDisplayEnabled: boolean;
    mobileClockInOut: boolean;
    allowClockInOutOutsideWorkHours: boolean;
    allowClockInOutOnSundayAndPublicHolidays: boolean;
    forceBreakClockInOut: boolean;
    forceSmallBreakDurationInMinutes: number;
    forceSmallBreakAfterXHours: number;
    forceBigBreakDurationInMinutes: number;
    forceBigBreakAfterXHours: number;
    forceBreakToBeTakenFrom?: string;
    forceBreakToBeTakenTo?: string;
    paidBreaksSundayPublicHolidays: boolean;
    paidBreaksSaturday: boolean;
    rule1PaidBreaksFrom?: string;
    rule1PaidBreaksTo?: string;
    rule1PaidBreaksMaximumDurationInMinutes: number;
    rule2PaidBreaksFrom?: string;
    rule2PaidBreaksTo?: string;
    rule2PaidBreaksMaximumDurationInMinutes: number;
    forceShiftStartTimeOnClockIn: boolean;
    forceShiftStartTimeBeforeInMinutes: number;
    forceShiftEndTimeOnClockOut: boolean;
    forceShiftEndTimeAfterInMinutes: number;
    timesheetMissingMode: TimesheetMissingMode;
    maximumWeeklyAdditionalWorkingTime: number;
    autoApproveDifferenceThresholdInMinutes?: number;
    missingTimesheetThresholdInMinutes?: number;
};

export type TimesheetSettingMutation = {
    name: string;
    timesheetMode: TimesheetMode;
    autofillTimesheet: boolean;
    includeShiftsIntoTimesheets: boolean;
    countDailyDifference: boolean;
    allowFutureInputs: boolean;
    cycleStartMonth: MONTHS;
    nightBonusPercentage: number;
    nightBonusStartTime?: string;
    nightBonusEndTime?: string;
    saturdayBonusPercentage: number;
    saturdayBonusStartTime?: string;
    saturdayBonusEndTime?: string;
    saturdayFromDayOfWeek?: DayOfWeek;
    saturdayToDayOfWeek?: DayOfWeek;
    sundayBonusPercentage?: number;
    sundayBonusStartTime?: string;
    sundayBonusEndTime?: string;
    sundayFromDayOfWeek?: DayOfWeek;
    sundayToDayOfWeek?: DayOfWeek;
    mandatoryComment: boolean;
    mobileClockInOut: boolean;
    breakDisplayEnabled: boolean;
    allowClockInOutOutsideWorkHours: boolean;
    allowClockInOutOnSundayAndPublicHolidays: boolean;
    forceBreakClockInOut: boolean;
    forceSmallBreakDurationInMinutes: number;
    forceSmallBreakAfterXHours: number;
    forceBigBreakDurationInMinutes: number;
    forceBigBreakAfterXHours: number;
    forceBreakToBeTakenFrom?: string;
    forceBreakToBeTakenTo?: string;
    paidBreaksSundayPublicHolidays: boolean;
    paidBreaksSundayPublicHolidaysMaximumDurationInMinutes: number;
    paidBreaksSaturday: boolean;
    paidBreaksSaturdayMaximumDurationInMinutes: number;
    rule1PaidBreaksFrom?: string;
    rule1PaidBreaksTo?: string;
    rule1PaidBreaksMaximumDurationInMinutes: number;
    rule2PaidBreaksFrom?: string;
    rule2PaidBreaksTo?: string;
    rule2PaidBreaksMaximumDurationInMinutes: number;
    forceShiftStartTimeOnClockIn: boolean;
    forceShiftStartTimeBeforeInMinutes: number;
    forceShiftEndTimeOnClockOut: boolean;
    forceShiftEndTimeAfterInMinutes: number;
    timesheetMissingMode: TimesheetMissingMode;
    maximumWeeklyAdditionalWorkingTime: number;
    autoApproveDifferenceThresholdInMinutes?: number;
    missingTimesheetThresholdInMinutes?: number;
};
