import { Objective, ObjectiveStatus, ObjectiveStatusCreateMutation, ObjectiveStatusUpdate } from '@/domain/objective/Objective.model';
import { FC, useState } from 'react';
import { createObjectiveStatus, deleteObjectiveStatus, updateObjectiveStatus } from '@/domain/objective/Objective.service';
import { handleError } from '@/utils/api.util';
import { IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addHours, getTodayDate, isBeforeDate } from '@/utils/datetime.util';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { SentIcon, Tick02Icon } from 'hugeicons-react';
import { BadProgressIcon, GoodProgressIcon, ModerateProgressIcon, MoreVerticalIcon } from '@/assets/icons/Icons';
import { ObjectiveStatusProgressIcon } from '@/page/objective/objective-history-dialog/ObjectiveHistoryDialog';
import { ActivityFeed } from '@/components/activity-feed/ActivityFeed';
import { canUpdateObjectiveStatus } from '@/domain/permission/Permission.service';
import { useCurrentEmployee, useCurrentPolicies } from '@/stores/store';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getNull } from '@/utils/object.util';
import { FieldText } from '@/components/form/field-text/FieldText';
import * as yup from 'yup';
import i18next from 'i18next';
import { OBJECTIVE_HISTORY_DIALOG_TAB_HEIGHT } from '@/page/objective/objective-history-dialog/ObjectiveHistoryDialog.constants';

type ObjectiveHistoryDialogStatusTabProps = {
    objective: Objective;
    onStatusUpdated: () => void;
};

export const ObjectiveHistoryDialogStatusTab: FC<ObjectiveHistoryDialogStatusTabProps> = ({ objective, onStatusUpdated }) => {
    const policies = useCurrentPolicies();
    const { t } = useTranslation();
    const readOnly = (objective?.assignee?.id && !canUpdateObjectiveStatus(policies, objective.assignee.id)) || objective.completionStatus;

    const handleDeleteObjectiveStatus = async (objectiveId: number, objectiveStatusUpdate: ObjectiveStatusUpdate) => {
        try {
            await deleteObjectiveStatus(objectiveId, objectiveStatusUpdate.id);
            onStatusUpdated();
        } catch (e) {
            handleError(e);
        }
    };

    const handleUpdateObjectiveStatusComment = async (objectiveStatusUpdate: ObjectiveStatusUpdate, comment: string) => {
        const mutation: ObjectiveStatusCreateMutation = {
            status: objectiveStatusUpdate.status,
            comment,
        };
        try {
            await updateObjectiveStatus(objective.id, objectiveStatusUpdate.id, mutation);
            onStatusUpdated();
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <Stack gap={3} py={1} height={OBJECTIVE_HISTORY_DIALOG_TAB_HEIGHT}>
            {!readOnly && <ModifyStatusSectionForm objective={objective} onStatusUpdated={onStatusUpdated} />}

            {objective.statusUpdates?.length ? (
                <Stack gap={2} overflow='auto'>
                    {[...objective.statusUpdates]
                        ?.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
                        .map(statusUpdate => (
                            <ObjectiveStatusUpdateRow
                                key={statusUpdate.id}
                                statusUpdate={statusUpdate}
                                onEdit={comment => handleUpdateObjectiveStatusComment(statusUpdate, comment)}
                                onDelete={() => handleDeleteObjectiveStatus(objective.id, statusUpdate)}
                            />
                        ))}
                </Stack>
            ) : (
                <Typography
                    sx={{
                        flex: 1,
                        alignSelf: 'center',
                        alignContent: 'center',
                    }}
                    variant={'body1'}
                >
                    {t('objectives.history_dialog.no_status_update')}
                </Typography>
            )}
        </Stack>
    );
};

type ObjectiveStatusUpdateRowProps = {
    statusUpdate: ObjectiveStatusUpdate;
    onEdit: (comment: string) => void;
    onDelete: () => void;
};
const ObjectiveStatusUpdateRow: FC<ObjectiveStatusUpdateRowProps> = props => {
    const { statusUpdate, onEdit, onDelete } = props;
    const [editMode, setEditMode] = useState(false);
    const [comment, setComment] = useState(statusUpdate.comment);
    const { t } = useTranslation();

    const canManageComment = (statusUpdate: ObjectiveStatusUpdate) => {
        // user can edit the comment within 1 hour
        const MAX_EDIT_TIME = 1;

        return isBeforeDate(getTodayDate(), addHours(statusUpdate.createdAt, MAX_EDIT_TIME));
    };

    const handleEditComment = (comment: string) => {
        onEdit(comment);
        setEditMode(false);
    };

    const items: BasicMenuItem[] = [
        {
            title: t('general.edit'),
            onClick: () => {
                setEditMode(true);
            },
        },
        {
            title: t('general.delete'),
            onClick: () => {
                onDelete();
            },
        },
    ];

    return (
        <Stack gap={0.5}>
            <Stack direction='row' gap={1} alignItems='center'>
                {statusUpdate.createdBy && <EmployeeAvatar employeeAvatar={statusUpdate.createdBy} size={'small'} />}

                {statusUpdate.createdBy && (
                    <Typography color='text.disabled'>
                        {t('objectives.status_update.create_by', {
                            displayName: statusUpdate.createdBy.displayName,
                            date: statusUpdate.updatedAt ?? statusUpdate.createdAt,
                        })}
                    </Typography>
                )}
                {statusUpdate.status && <ObjectiveStatusProgressIcon objectiveStatus={statusUpdate.status} />}
            </Stack>
            <Stack direction='row' gap={1} alignItems='center'>
                {editMode ? (
                    <TextField
                        fullWidth
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        multiline
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => handleEditComment(comment)} size='small' edge='end' aria-label={'save-comment'}>
                                            <Tick02Icon fontSize={'small'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                ) : (
                    <ActivityFeed>{statusUpdate.comment}</ActivityFeed>
                )}

                {canManageComment(statusUpdate) && <BasicMenu items={items} endIcon={<MoreVerticalIcon />} />}
            </Stack>
        </Stack>
    );
};

const schema = yup.object().shape({
    status: yup.string().oneOf(Object.values(ObjectiveStatus), i18next.t('general.validation.required')).nullable(),
    comment: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

type ModifyStatusSectionFormProps = {
    objective: Objective;
    onStatusUpdated: () => void;
};

const ModifyStatusSectionForm: FC<ModifyStatusSectionFormProps> = ({ objective, onStatusUpdated }) => {
    const currentEmployee = useCurrentEmployee();
    const { t } = useTranslation();

    const { handleSubmit, control, setValue } = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            // by default, the last status update is the current status
            status: objective.lastStatusUpdate?.status ?? getNull(),
            comment: '',
        },
    });

    const handleSave = async (values: FormValues) => {
        if (!objective.id) {
            return;
        }
        try {
            const objectiveStatusUpdate = { ...values, status: values.status ?? undefined };

            await createObjectiveStatus(objective.id, objectiveStatusUpdate);
            onStatusUpdated();
            setValue('comment', '');
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'space-between'}>
            <Stack flex={1}>
                <Stack direction='row' gap={1} alignItems='center'>
                    <Typography variant={'body1bold'}>{t('objectives.history_dialog.modify_status')}</Typography>

                    <Controller
                        name='status'
                        control={control}
                        render={({ field: { value, onChange, ...restField } }) => {
                            return (
                                <ObjectiveStatusSelect
                                    {...restField}
                                    value={value ?? getNull()}
                                    onChange={(value: ObjectiveStatus | null) => {
                                        onChange(value);
                                    }}
                                />
                            );
                        }}
                    />
                </Stack>
                <Stack direction='row' gap={1} alignItems='center'>
                    {currentEmployee && <EmployeeAvatar employeeAvatar={currentEmployee} size={'small'} />}
                    <FieldText
                        name='comment'
                        control={control}
                        fullWidth
                        placeholder={t('objectives.history_dialog.add_comment')}
                        textFieldProps={{
                            multiline: true,
                        }}
                    />
                </Stack>
            </Stack>

            <IconButton onClick={handleSubmit(handleSave, console.error)} size='medium' edge='end'>
                <SentIcon fontSize='small' />
            </IconButton>
        </Stack>
    );
};

type ObjectiveStatusSelectProps = {
    value: ObjectiveStatus | null;
    onChange: (value: ObjectiveStatus | null) => void;
};

const ObjectiveStatusSelect: FC<ObjectiveStatusSelectProps> = ({ value, onChange }) => {
    const { t } = useTranslation();

    const handleOnChange = (newValue: ObjectiveStatus) => {
        onChange(newValue === value ? getNull() : newValue);
    };

    return (
        <Stack direction='row' alignItems='center'>
            <Tooltip title={t('objectives.status.off_track')}>
                <IconButton onClick={() => handleOnChange(ObjectiveStatus.DELAYED)}>
                    <BadProgressIcon opacity={value === 'DELAYED' ? 1 : 0.3} />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('objectives.status.at_risk')}>
                <IconButton onClick={() => handleOnChange(ObjectiveStatus.AT_RISK)}>
                    <ModerateProgressIcon opacity={value === 'AT_RISK' ? 1 : 0.3} />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('objectives.status.on_track')}>
                <IconButton onClick={() => handleOnChange(ObjectiveStatus.ON_TRACK)}>
                    <GoodProgressIcon opacity={value === 'ON_TRACK' ? 1 : 0.3} />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};
