import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Employee, EmployeeAnniversaryRequest, EmployeeAvatar as EmployeeAvatarType, EmployeePolicy } from '@/domain/employee/Employee.model';
import { getDaysUntilNextBirthday, getUserStatusTranslationKey, uploadAvatar } from '@/domain/employee/Employee.service';
import { Employment, EmploymentCreateReason, EmploymentStatus } from '@/domain/employment/Employment.model';
import { getCurrentPrincipalEmployment, getDaysUntilProbationEnd } from '@/domain/employment/Employment.service';
import {
    canManageEmployeeAvatars,
    canOnboardOffboardEmployees,
    canSeeOtherEmployeeLeaves,
    canViewAtLeastOneProfileInfoTab,
    canViewEmployeeBirthdays,
    canViewEmployeeContracts,
    canViewEmployeeEmails,
    canViewEmployeePhoneNumbers,
    canViewEmployeesBasicInfo,
    canViewTimesheets,
    hasConfigurePermissionPolicy,
} from '@/domain/permission/Permission.service';
import { useGetEmployee, useGetEmployeesBirthdays } from '@/hooks/employee/Employee.hook';
import { EmployeeProfileActionButton } from '@/page/employee-profile/employee-profile-info/EmployeeProfileActionButton/EmployeeProfileActionButton';
import { EmployeeProfileActionType } from '@/stores/reducers/employeeProfileActions';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import {
    Button,
    ButtonBase,
    Chip,
    CircularProgress,
    IconButton,
    Link,
    Paper,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Department } from '@/domain/department/Department.model';
import { EmployeeAvailabilitySearchRequest } from '@/domain/employee-availability/EmployeeAvailability.model';
import { getEmployeesAvailability } from '@/domain/employee-availability/EmployeeAvailability.service';
import { getCurrentEmployeeAllLeaveTypePolicies, getEmployeeLeaveTypeToDisplay } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { Location } from '@/domain/location/Location.model';
import { PermissionGroup } from '@/domain/permission-group/PermissionGroup.model';
import { getGroupsByEmployeeId } from '@/domain/permission-group/PermissionGroup.service';
import { RealmFeature } from '@/domain/realm/Realm.model';
import { TimesheetSearch, TimesheetsRequestStatus } from '@/domain/timesheet/Timesheet.model';
import { getOverlappingTimesheets, searchTimesheets } from '@/domain/timesheet/Timesheet.service';
import { MobileProfileTabsNavigation } from '@/page/employee-profile/employee-profile-mobile-navigation/MobileProfileTabsNavigation';
import { useAppDispatch, useAppSelector, useCurrentEmployee, useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { differenceInDays, getCurrentLocalDate, getEndOfYear, getStartOfYear, isSameDate, toDate } from '@/utils/datetime.util';

import { FilePickerModal, TemporaryFile } from '@/components/file-picker/FilePicker';
import { getUniqueTranslationLabels } from '@/domain/label/Label.service';
import { useGetSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import {
    EmployeeWorkingAreaOrLeaveChip,
    WorkingAreaOrLeave,
    WorkingAreaOrLeaveType,
} from '@/page/employee/employee-working-area-or-leave-chip/EmployeeWorkingAreaOrLeaveChip';
import { formatPhoneNumber } from '@/utils/strings.util';
import { useQueryClient } from '@tanstack/react-query';
import { Call02Icon, CallIcon, Mail01Icon, SquareLock02Icon, UserSquareIcon } from 'hugeicons-react';

const fetchTimesheets = async (employeeId: number) => {
    const request: TimesheetSearch = {
        employeeIds: [employeeId],
        startDate: getCurrentLocalDate(),
        endDate: getCurrentLocalDate(),
        statuses: [TimesheetsRequestStatus.APPROVED, TimesheetsRequestStatus.PENDING],
    };

    try {
        const timesheets = await searchTimesheets(request);
        return getOverlappingTimesheets(timesheets);
    } catch (error) {
        handleError(error);
    }
};

const fetchLeaveReturnInfo = async (employeeId: number) => {
    const searchRequest: EmployeeAvailabilitySearchRequest = {
        atDateTime: getCurrentLocalDate(),
        employeeIds: [employeeId],
    };

    try {
        const employeesAvailability = await getEmployeesAvailability(searchRequest);
        if (employeesAvailability.length > 0) {
            return employeesAvailability[0];
        }
    } catch (error) {
        handleError(error);
    }
};

const fetchLeaveTypePolicies = async (employeeId: number, leaveTypeId: number) => {
    try {
        const userLeavePolicies = await getCurrentEmployeeAllLeaveTypePolicies();
        return getEmployeeLeaveTypeToDisplay(userLeavePolicies, employeeId, leaveTypeId);
    } catch (error) {
        console.error(error);
    }
    return undefined;
};

const getLeaveToDisplay = async (employeeId: number): Promise<WorkingAreaOrLeave | undefined> => {
    const leaveReturnInfo = await fetchLeaveReturnInfo(employeeId);
    if (leaveReturnInfo?.leaveTypeId) {
        const leaveTypePolicy = await fetchLeaveTypePolicies(employeeId, leaveReturnInfo.leaveTypeId);
        return {
            displayText: getLabelTranslation(leaveTypePolicy?.name),
            color: leaveTypePolicy?.color,
            leaveEndDate: leaveReturnInfo.returnDate,
            leaveReturnDayPeriod: leaveReturnInfo.returnPeriod,
            leavePercentage: leaveReturnInfo.leavePercentage,
            type: 'LEAVE' as WorkingAreaOrLeaveType,
        };
    }
    return undefined;
};

const getTimesheetToDisplay = async (employeeId: number): Promise<WorkingAreaOrLeave | undefined> => {
    const timesheet = await fetchTimesheets(employeeId);
    if (timesheet) {
        return {
            displayText: timesheet?.area?.name,
            color: timesheet?.area?.color,
            type: 'TIMESHEET' as WorkingAreaOrLeaveType,
            leaveEndDate: undefined,
            leaveReturnDayPeriod: undefined,
            leavePercentage: undefined,
        };
    }
    return undefined;
};

const fetchCurrentWorkingAreaOrLeave = async (employeeId: number, currentEmployeeId: number, realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => {
    const canViewLeaves = canSeeOtherEmployeeLeaves(realmFeatures, policies, currentEmployeeId);
    if (canViewLeaves) {
        const leaveTitleToDisplay = await getLeaveToDisplay(employeeId);
        if (leaveTitleToDisplay) {
            return leaveTitleToDisplay;
        }
    }
    const canViewTimesheet = canViewTimesheets(realmFeatures, policies, employeeId);
    if (canViewTimesheet) {
        return await getTimesheetToDisplay(employeeId);
    }
};

export const EmployeeProfileCard: FC = () => {
    const [permissionGroupEmployee, setPermissionGroupEmployee] = useState<PermissionGroup[]>([]);

    // hack to refetch employee, need to refactor this when we will use Redux Toolkit or React Query
    const shouldRefetchEmployee = !!useAppSelector(state => state.employeeProfile?.refetchEmployee);

    const currentEmployee = useCurrentEmployee();
    const employeeId = useEmployeeProfileId();

    const {
        data: employee,
        refetch: refetchEmployeeById,
        isLoading: isEmployeeByIdLoading,
        isError: isEmployeeByIdError,
        error: employeeError,
    } = useGetEmployee(employeeId);
    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();
    const dispatch = useAppDispatch();
    const [currentWorkingAreaOrLeave, setCurrentWorkingAreaOrLeave] = useState<WorkingAreaOrLeave>();

    const birthdaySearchRequest: EmployeeAnniversaryRequest = {
        startDate: getStartOfYear(),
        endDate: getEndOfYear(),
        employeeIds: employeeId ? [employeeId] : [],
    };

    const isViewBirthdayEnabled = !!employeeId && canViewEmployeeBirthdays(policies, employeeId);

    const {
        data: employeeBirthdays,
        isLoading: isEmployeeBirthdaysLoading,
        refetch: refetchEmployeeBirthdays,
    } = useGetEmployeesBirthdays(birthdaySearchRequest, { enabled: isViewBirthdayEnabled });

    const fetchGroupsByEmployeeId = useCallback(async (employeeId: number) => {
        try {
            const data = await getGroupsByEmployeeId(employeeId);
            setPermissionGroupEmployee(data);
        } catch (error) {
            handleError(error);
        }
    }, []);

    useEffect(() => {
        if (employeeId && hasConfigurePermissionPolicy(policies)) {
            fetchGroupsByEmployeeId(employeeId).catch(handleError);
        }
    }, [fetchGroupsByEmployeeId, policies, employeeId]);

    const getRefetchEmployee = useCallback(() => {
        refetchEmployeeById().catch(handleError);
        refetchEmployeeBirthdays().catch(handleError);
    }, [refetchEmployeeById, refetchEmployeeBirthdays]);

    const fetchCurrentWorking = useCallback(async () => {
        const currentLocation = await fetchCurrentWorkingAreaOrLeave(employeeId, currentEmployee?.id, realm.realmFeatures, policies);
        setCurrentWorkingAreaOrLeave(currentLocation);
    }, [employeeId, currentEmployee, realm.realmFeatures, policies]);

    useEffect(() => {
        fetchCurrentWorking().catch(handleError);
    }, [fetchCurrentWorking]);

    // hack to refetch employee, need to refactor this when we will use Redux Toolkit or React Query
    useEffect(() => {
        if (!shouldRefetchEmployee) {
            return;
        }
        getRefetchEmployee();
        fetchCurrentWorking().catch(handleError);
        dispatch({ type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE, refetchEmployee: false });
    }, [dispatch, fetchCurrentWorking, getRefetchEmployee, refetchEmployeeById, refetchEmployeeBirthdays, shouldRefetchEmployee]);

    const handleAvatarImageChange = () => {
        refetchEmployeeById().catch(handleError);
    };

    const isLoading = isEmployeeByIdLoading || isEmployeeBirthdaysLoading;

    return (
        <Paper
            sx={{
                padding: 2,
                position: 'sticky',
                top: 0,
                bottom: 0,
                flexGrow: 1,
            }}
        >
            <StateHandler loadingComponent={<EmployeeProfileCardSkeleton />} isLoading={isLoading} isError={isEmployeeByIdError} error={employeeError}>
                {employee && (
                    <Stack gap={3} height={'100%'}>
                        <AvatarInfo employee={employee} policies={policies} onAvatarImageChange={handleAvatarImageChange} />
                        <EmployeeInfos
                            employee={employee}
                            permissionGroupEmployee={permissionGroupEmployee}
                            refetchEmployee={getRefetchEmployee}
                            currentWorkingAreaOrLeave={currentWorkingAreaOrLeave}
                            birthday={employeeBirthdays?.[0]?.anniversaryDate}
                        />
                    </Stack>
                )}
            </StateHandler>
        </Paper>
    );
};

const EmployeeProfileCardSkeleton: FC = () => {
    return (
        <Stack gap={3} height={'100%'}>
            <AvatarInfoSkeleton />
            <EmployeeInfosSkeleton />
        </Stack>
    );
};

export const AvatarInfoSkeleton: FC = () => {
    return (
        <Stack gap={2}>
            <Skeleton variant='circular' width={120} height={120} />
            <Stack>
                <Skeleton variant='text' width={120} height={36} />
                <Skeleton variant='text' width={100} height={24} />
            </Stack>
        </Stack>
    );
};

export const EmployeeInfosSkeleton: FC = () => {
    return (
        <Stack justifyContent={'space-between'} flex={1}>
            <Stack height={'100%'} gap={0.5}>
                <Skeleton variant='text' width={220} height={24} />
                <Skeleton variant='text' width={160} height={24} />
                <Skeleton variant='text' width={120} height={24} />
                <Skeleton variant='text' width={220} height={24} />
                <Skeleton variant='text' width={160} height={24} />
                <Skeleton variant='text' width={120} height={24} />
            </Stack>
            <Skeleton variant='rounded' height={28} width={'60%'} />
        </Stack>
    );
};

type AvatarInfoProps = {
    employee: Employee;
    policies: EmployeePolicy[];
    onAvatarImageChange: (avatarImageUrl: string | undefined) => void;
};

export const AvatarInfo: FC<AvatarInfoProps> = ({ employee, policies, onAvatarImageChange }) => {
    const [isAvatarLoading, setIsAvatarLoading] = useState(false);
    const queryClient = useQueryClient();

    const canChangeAvatar = canManageEmployeeAvatars(policies, employee?.id);

    const getUniqueJobNames = (employments: Employment[]): string[] => {
        return Array.from(new Set(employments.map(({ job }) => getLabelTranslation(job.name))));
    };

    const handleFilesUpload = async (file: TemporaryFile) => {
        try {
            setIsAvatarLoading(true);
            const employeeData = await uploadAvatar(employee.id, file.data);
            // TODO find a way to manage keys https://rogerhr.atlassian.net/browse/RP-6225 https://rogerhr.atlassian.net/browse/RP-6224
            await queryClient.invalidateQueries({ queryKey: [`getEmployeeAvatarUrl_${employee.id}`] });
            onAvatarImageChange(employeeData.avatarImageUrl);
            setIsAvatarLoading(false);
        } catch (error) {
            handleError(error);
        }
    };

    const disabled = !policies || !canChangeAvatar || isAvatarLoading;

    return (
        <Stack alignItems={'flex-start'} gap={2}>
            <FilePickerModal disabled={disabled} onFilePicked={handleFilesUpload}>
                {isAvatarLoading ? (
                    <CircularProgress size={120} thickness={1} />
                ) : (
                    <ButtonBase disableRipple disabled={disabled}>
                        <EmployeeAvatar sx={{ width: '120px', height: '120px', fontSize: '50px' }} employeeAvatar={employee} />
                    </ButtonBase>
                )}
            </FilePickerModal>

            <Stack>
                <Typography variant={'h1'} color={'text.primary'}>
                    {employee?.displayName}
                </Typography>
                <Typography variant={'h2'} color={'text.secondary'}>
                    {getUniqueJobNames(employee?.currentEmployments).join(', ')}
                </Typography>
            </Stack>
        </Stack>
    );
};

type EmployeeInfosProps = {
    employee: Employee;
    permissionGroupEmployee: PermissionGroup[];
    refetchEmployee: () => void;
    currentWorkingAreaOrLeave: WorkingAreaOrLeave | undefined;
    birthday: LocalDate | undefined;
};

const EmployeeInfos: FC<EmployeeInfosProps> = ({ employee, permissionGroupEmployee, refetchEmployee, birthday, currentWorkingAreaOrLeave }) => {
    const { t } = useTranslation();
    const policies = useCurrentPolicies();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = !isMobile;

    const showPhoneNumber = canViewEmployeePhoneNumbers(policies, employee.id);
    const showEmail = canViewEmployeeEmails(policies, employee.id);
    const showContractInfo = canViewEmployeeContracts(policies, employee.id);
    const showEmployeeBasicInfo = canViewEmployeesBasicInfo(policies, employee.id);

    const currentEmployment = getCurrentPrincipalEmployment(employee);

    const principalDepartment = currentEmployment?.department;
    const secondaryDepartments = employee.currentEmployments
        .map(employment => employment.department)
        .filter(department => department.id !== principalDepartment?.id);

    const principalLocation = currentEmployment?.location;
    const secondaryLocations = employee.currentEmployments.map(employment => employment.location).filter(location => location.id !== principalLocation?.id);

    // Set managers from principal employment at first, then add managers from other employments
    const managers = [...(currentEmployment?.managers ?? []), ...employee.currentEmployments.flatMap(employment => employment.managers)];
    return (
        <Stack justifyContent={'space-between'} flex={1}>
            <Stack height={'100%'} gap={0.5}>
                {isMobile && <ActionsBar employee={employee} showPhoneNumber={showPhoneNumber} showEmail={showEmail} />}
                {isDesktop && (
                    <EmployeeDetails employee={employee} showPhoneNumber={showPhoneNumber} showEmail={showEmail} showEmployeeCode={showEmployeeBasicInfo} />
                )}
                <DepartmentInfo principalDepartment={principalDepartment} secondaryDepartments={secondaryDepartments} />
                <LocationInfo principalLocation={currentEmployment?.location} secondaryLocations={secondaryLocations} />
                <ManagersInfo managers={managers} />
                <SubordinateInfo subordinates={employee?.subordinates ?? []} />
                {hasConfigurePermissionPolicy(policies) && <PermissionsGroupsInfo permissionGroupEmployee={permissionGroupEmployee} />}
                {showContractInfo && (
                    <Stack gap={1}>
                        <ContractStartInfoTag
                            employmentStartDate={currentEmployment?.startDate}
                            employmentCreateReason={currentEmployment?.employmentCreateReason}
                            employmentStatus={employee?.employmentStatus}
                        />
                        <ProbationEndInfoTag probationEndDate={currentEmployment?.probationEndDate} employmentStartDate={currentEmployment?.startDate} />
                        <EmployeeAnniversaryInfoTag birthday={birthday} />
                    </Stack>
                )}
                <CurrentWorkingAreaOrLeaveInfo currentWorkingArea={currentWorkingAreaOrLeave} />
            </Stack>
            <Stack gap={1}>
                {canOnboardOffboardEmployees(policies) && (
                    <>
                        <Typography variant={'body1'}>
                            {t('employee.login_status_label')} : <b>{t(getUserStatusTranslationKey(), { context: employee.status })}</b>
                        </Typography>
                        <EmployeeProfileActions employee={employee} refetchEmployee={refetchEmployee} />
                    </>
                )}
            </Stack>
        </Stack>
    );
};

type CurrentLocationInfoProps = {
    currentWorkingArea: WorkingAreaOrLeave | undefined;
};
const CurrentWorkingAreaOrLeaveInfo: FC<CurrentLocationInfoProps> = ({ currentWorkingArea }) => {
    if (!currentWorkingArea) {
        return;
    }

    return (
        <Stack direction={'row'} gap={1} alignItems={'left'} height={24} alignSelf={'left'}>
            <EmployeeWorkingAreaOrLeaveChip currentWorkingAreaLeave={currentWorkingArea} />
        </Stack>
    );
};

type EmployeeDetailsProps = {
    employee: Employee;
    showPhoneNumber: boolean;
    showEmail: boolean;
    showEmployeeCode: boolean;
};

const EmployeeDetails: FC<EmployeeDetailsProps> = ({ employee, showPhoneNumber, showEmail, showEmployeeCode }) => {
    if (!showPhoneNumber && !showEmail && !showEmployeeCode) {
        return;
    }

    if (!employee.email && !employee.phoneNumber && !employee.employeeCode) {
        return;
    }

    return (
        <Stack>
            {showEmployeeCode && employee.employeeCode && (
                <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
                    <UserSquareIcon size={20} />
                    <Typography variant={'body1bold'} color={'text.secondary'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {employee.employeeCode}
                    </Typography>
                </Stack>
            )}

            {showEmail && (
                <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
                    <Mail01Icon size={20} />
                    <Typography variant={'body1bold'} color={'text.secondary'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {employee.email}
                    </Typography>
                </Stack>
            )}

            {showPhoneNumber && employee.phoneNumber && (
                <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
                    <Call02Icon size={20} />
                    <Typography variant={'body1bold'} color={'text.secondary'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {formatPhoneNumber(employee.phoneNumber)}
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
};

type ActionsBarProps = { employee: Employee; showPhoneNumber: boolean; showEmail: boolean };

const ActionsBar: FC<ActionsBarProps> = ({ employee, showPhoneNumber, showEmail }) => {
    const { t } = useTranslation();
    const [openMenu, setOpenMenu] = useState(false);

    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();

    const { data: sectionDefinitions = [] } = useGetSectionDefinitions();

    const showNavigationButton = canViewAtLeastOneProfileInfoTab(
        realm?.realmFeatures,
        policies,
        employee.id,
        sectionDefinitions.map(({ id }) => id),
    );

    if (!showPhoneNumber && !showEmail && !showNavigationButton) {
        return;
    }

    return (
        <Stack direction={'row'} alignItems='center' gap={1} py={0.5}>
            {showEmail && (
                <Link href={employee?.email ? `mailto:${employee.email}` : ''}>
                    <Button sx={{ p: 1, minWidth: 'auto' }} aria-label={t('employee_profile.email')}>
                        <Mail01Icon size={16} />
                    </Button>
                </Link>
            )}
            {showPhoneNumber && (
                <Link href={employee?.phoneNumber ? `tel:${employee.phoneNumber}` : ''}>
                    <Button sx={{ p: 1, minWidth: 'auto' }} aria-label={t('employee_profile.phone_number')}>
                        <CallIcon size={16} />
                    </Button>
                </Link>
            )}
            {showNavigationButton && (
                <>
                    <Button onClick={() => setOpenMenu(true)} sx={{ flex: 1 }}>
                        {t('employee_profile.go_to')}
                    </Button>
                    <MobileProfileTabsNavigation employeeId={employee.id} open={openMenu} onClose={() => setOpenMenu(false)} />
                </>
            )}
        </Stack>
    );
};

type DepartmentInfoProps = {
    principalDepartment?: Department;
    secondaryDepartments?: Department[];
};

const DepartmentInfo: FC<DepartmentInfoProps> = ({ principalDepartment, secondaryDepartments = [] }) => {
    const { t } = useTranslation();
    if (!principalDepartment?.name) {
        return;
    }

    const [principalName, ...restNames] = getUniqueTranslationLabels([principalDepartment, ...secondaryDepartments].map(({ name }) => name));
    const hasSecondaries = !!restNames.length;
    const allNamesJoined = [principalName, ...restNames].join(', ');
    const extra = hasSecondaries ? `(+${restNames.length})` : '';

    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
            <Typography variant={'body1'} color={'text.secondary'}>
                {t('employee_profile.department')}
            </Typography>
            <Tooltip title={allNamesJoined}>
                <Typography variant={'body1bold'} color={'text.secondary'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {`${principalName} ${extra}`}
                </Typography>
            </Tooltip>
        </Stack>
    );
};

type LocationInfoProps = {
    principalLocation?: Location;
    secondaryLocations?: Location[];
};

const LocationInfo: FC<LocationInfoProps> = ({ principalLocation, secondaryLocations = [] }) => {
    const { t } = useTranslation();
    if (!principalLocation?.name && !principalLocation?.city) {
        return;
    }

    const getUniqueLocationNames = (locations: Location[]): string[] => {
        return Array.from(new Set(locations.map(location => location.name)));
    };

    const [principalName, ...restNames] = getUniqueLocationNames([principalLocation, ...secondaryLocations]);
    const hasSecondaries = !!restNames.length;
    const allNamesJoined = [principalName, ...restNames].join(', ');
    const extra = hasSecondaries ? `(+${restNames.length})` : '';

    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
            <Typography variant={'body1'} color={'text.secondary'}>
                {t('employee_profile.location')}
            </Typography>
            <Tooltip title={hasSecondaries ? allNamesJoined : undefined}>
                <Typography variant={'body1bold'} color={'text.secondary'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {`${principalName} ${extra}`}
                </Typography>
            </Tooltip>
        </Stack>
    );
};

type ManagersInfoProps = {
    managers: EmployeeAvatarType[];
};

const ManagersInfo: FC<ManagersInfoProps> = ({ managers }) => {
    const { t } = useTranslation();

    if (!managers.length) {
        return;
    }

    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
            <Typography variant={'body1'} color={'text.secondary'}>
                {t('employee_profile.manager')}
            </Typography>
            <StackedAvatars employeeAvatars={managers} profileLink />
        </Stack>
    );
};

type SubordinateInfoProps = {
    subordinates: Employee[];
};

const SubordinateInfo: FC<SubordinateInfoProps> = ({ subordinates }) => {
    const { t } = useTranslation();

    if (!subordinates?.length) {
        return;
    }

    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
            <Typography variant={'body1'} color={'text.secondary'}>
                {t('employee_profile.direct_reports')}
            </Typography>
            <StackedAvatars employeeAvatars={subordinates} profileLink />
        </Stack>
    );
};

type ContractStartInfoTagProps = {
    employmentStartDate?: LocalDate;
    employmentStatus?: EmploymentStatus;
    employmentCreateReason?: EmploymentCreateReason;
};

export const ContractStartInfoTag: FC<ContractStartInfoTagProps> = ({ employmentStartDate, employmentStatus, employmentCreateReason }) => {
    const { t } = useTranslation();

    const getDaysUntilContractStart = (employmentStartDate?: LocalDate) => {
        if (!employmentStartDate) {
            return 0;
        }
        const today = getCurrentLocalDate();
        const diffInDays = differenceInDays(employmentStartDate, today);
        if (diffInDays === 0 && isSameDate(employmentStartDate, today)) {
            return 0;
        }
        if (diffInDays < 0) {
            return diffInDays;
        }
        return diffInDays;
    };

    const getDaysUntilContractStartLabel = (daysUntilContractStart: number) => {
        return t('employee_profile.contract_start_future_employee_info_tag', {
            count: daysUntilContractStart,
        });
    };

    const daysUntilContractStart = getDaysUntilContractStart(employmentStartDate);
    const isContractStartDateInFuture = daysUntilContractStart >= 0;
    const isEmployeeHired = employmentStatus === EmploymentStatus.HIRED || employmentStatus === EmploymentStatus.EMPLOYED;
    const isNewEmployee = employmentCreateReason === EmploymentCreateReason.NEW_EMPLOYEE || employmentCreateReason === EmploymentCreateReason.REHIRED;
    const showLabelForFutureEmployee = isContractStartDateInFuture && isEmployeeHired;

    if (!showLabelForFutureEmployee || !isNewEmployee) {
        return;
    }

    return <Chip icon={<>🚀</>} color='error' label={getDaysUntilContractStartLabel(daysUntilContractStart)} />;
};

type ProbationEndInfoTagProps = {
    probationEndDate?: LocalDate;
    employmentStartDate?: LocalDate;
};

export const ProbationEndInfoTag: FC<ProbationEndInfoTagProps> = ({ probationEndDate, employmentStartDate }) => {
    const { t } = useTranslation();
    const getDaysUntilProbationEndLabel = (daysUntilProbationEnd: number) => {
        return t('employee_profile.probation_end_info_tag', {
            count: daysUntilProbationEnd,
        });
    };

    const showProbationEndInfoTag = (probationEndDate?: LocalDate, employmentStartDate?: LocalDate) => {
        const today = getCurrentLocalDate();
        if (!probationEndDate || !employmentStartDate) {
            return false;
        }
        const isTodayAfterEmploymentStartDate = toDate(today) > toDate(employmentStartDate);
        const isTodayBeforeProbationEndDate = toDate(today) < toDate(probationEndDate) || isSameDate(today, probationEndDate);
        return isTodayAfterEmploymentStartDate && isTodayBeforeProbationEndDate;
    };

    if (!showProbationEndInfoTag(probationEndDate, employmentStartDate)) {
        return;
    }

    const daysUntilProbationEnd = probationEndDate ? getDaysUntilProbationEnd(probationEndDate) : 0;
    const daysUntilProbationEndLabel = getDaysUntilProbationEndLabel(daysUntilProbationEnd);

    return <Chip icon={<>🤝</>} color='warning' label={daysUntilProbationEndLabel} />;
};

type EmployeeAnniversaryInfoTagProps = {
    birthday: LocalDate | undefined;
};
export const EmployeeAnniversaryInfoTag: FC<EmployeeAnniversaryInfoTagProps> = ({ birthday }) => {
    const { t } = useTranslation();

    const getDaysUntilBirthdayLabel = (birthday: LocalDate) => {
        const daysUntilBirthday = getDaysUntilNextBirthday(birthday);
        return t('employee_profile.birthday_info_tag', { daysUntilBirthday, count: daysUntilBirthday });
    };
    const showBirthdayInfoTag = (birthday: LocalDate) => {
        const daysUntilBirthday = getDaysUntilNextBirthday(birthday);

        const maxDaysToShowBirthdayInfoTag = 1;
        return daysUntilBirthday <= maxDaysToShowBirthdayInfoTag;
    };

    if (!birthday || !showBirthdayInfoTag(birthday)) {
        return;
    }

    return <Chip icon={<>🥳</>} color='info' label={getDaysUntilBirthdayLabel(birthday)} />;
};

type PermissionsGroupsInfoProps = {
    permissionGroupEmployee: PermissionGroup[];
};

export const PermissionsGroupsInfo: FC<PermissionsGroupsInfoProps> = ({ permissionGroupEmployee }) => {
    const { t } = useTranslation();
    const showPermissionGroupsInfo = (permissionGroupEmployee: PermissionGroup[]) => {
        return !!permissionGroupEmployee?.length;
    };

    if (!showPermissionGroupsInfo(permissionGroupEmployee)) {
        return;
    }
    return (
        <Stack direction={'row'} alignItems={'center'} gap={0.5} height={32}>
            <Typography variant={'body1'} color={'text.secondary'}>
                {t('employee_profile.permissions_groups')}
            </Typography>
            <Tooltip
                title={permissionGroupEmployee
                    .map(permissionGroup => {
                        return permissionGroup.groupName;
                    })
                    .join(', ')}
            >
                <IconButton sx={{ padding: 0 }} disableRipple>
                    <SquareLock02Icon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

type EmployeeProfileActionsProps = {
    employee: Employee;
    refetchEmployee: () => void;
};

export const EmployeeProfileActions: FC<EmployeeProfileActionsProps> = ({ employee, refetchEmployee }) => {
    return (
        <Stack direction='row' justifyContent='flex-start' alignItems='center'>
            <EmployeeProfileActionButton onSuccess={refetchEmployee} employee={employee} />
        </Stack>
    );
};
