import { EmployeeReviewFeedbackItemSearch, EmployeeReviewFeedbackResult } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { getEmployeeReviewFeedbacksResultsItems } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { getReview, searchReviews } from '@/domain/review/Review.service';
import { createQueryHook, createRequiredParamsQueryHook, UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetEmployeeReviewFeedbacksItems = (
    search: EmployeeReviewFeedbackItemSearch = {},
    options: { enabled?: boolean } = { enabled: true },
): UseQueryResult<EmployeeReviewFeedbackResult[]> => {
    const [feedbacksItems, setFeedbacksItems] = useState<EmployeeReviewFeedbackResult[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeReviewFeedbacksItems = useCallback(
        async (filterParams: EmployeeReviewFeedbackItemSearch) => {
            if (!options.enabled) {
                return;
            }
            try {
                const data = await getEmployeeReviewFeedbacksResultsItems(filterParams);
                setFeedbacksItems(data);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [options.enabled],
    );

    useDeepCompareEffect(() => {
        fetchEmployeeReviewFeedbacksItems(search).catch(handleError);
    }, [fetchEmployeeReviewFeedbacksItems, search]);

    return {
        data: feedbacksItems,
        setData: setFeedbacksItems,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeReviewFeedbacksItems(search),
    };
};

export const useGetReview = createRequiredParamsQueryHook('review', getReview);

export const useGetReviews = createQueryHook('reviews', searchReviews);
